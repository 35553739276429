/*
--------------------------------------------------------------------------------------- FEATURES

------------------------------------------------------------------------------------------------
*/

.advert {
    display:block;
    text-align: center;
    padding: 20px 0 40px 0;
}

.advert h1:last-child,
.advert h2:last-child,
.advert h3:last-child,
.advert h4:last-child, {
    margin-bottom:0;
}

.advert h1 span,
.advert h2 span,
.advert h3 span,
.advert h4 span {
    color: $primary;
}

.advert-cta-buttons {
    @media screen and (min-width: $break-tablet) {
        display: flex;
        justify-content: center;
    }
    //
    @include clrfix;
}



.advert  + .advert {
    padding-top:0;
}

/*
.advert {
    background-color:$bg-grey-paler;
    text-align: center;
}

.advert-img {
    color:$white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

}

.advert-img h2 {
    color:$white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.advert-img p {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.advert-img .btn {
    box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.2);
}

*/

/* GRID */
@media screen and (min-width: $break-landscape) {//  496px/16


}
