/*
------------------------------------------------------------------------- CUSTOM TOGGLE SWITCHES

------------------------------------------------------------------------------------------------
*/

.toggle {
    position: relative;
    user-select: none;
    /**/
    font-size: 1rem;
    line-height: 17px;
    min-width: 17px;
}

/* CHECKBOX */
.toggle [type="checkbox"] {
    margin:0;
    padding: 0;
    /**/
    box-sizing: border-box;
    width: 3.5rem;
    height: 1.5rem;
    /**/
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    outline: 0;
}


/* LABEL */
.toggle [type="checkbox"] + label {
    display: block;
    position: relative;
    min-height: 1.5rem;
    padding: .15em 0 0 3.2rem;
    color: rgba(255,255,255,.87);
}

.toggle [type="checkbox"] + label::before,
.toggle [type="checkbox"] + label::after {
    content: "";
    position: absolute;
    height: 1.5em;
    transition: all 0.25s ease;
    border-radius: 500rem;
}

/* LABEL BG */
.toggle [type="checkbox"] + label::before {

    left: 0;
    top: 0;

    background: rgba(255,255,255,.4);

    width: 3rem;
    height: 1.5rem;

}

/* LABEL CIRCLE */
.toggle [type="checkbox"] + label::after {
    left: -.05rem;
    top: 0;
    background-color: #fff;
    /*background-image: linear-gradient(transparent,rgba(255,255,255,.05));*/
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 2;
    box-shadow: 0 1px 2px 0 rgba(34,34,34,.15), inset 0 0 0 1px rgba(34,34,34,.25);
}


/* HOVER / FOCUS */

.toggle [type="checkbox"] + label:hover {
    cursor: pointer;
}

.toggle [type="checkbox"] + label:hover::before,
.toggle [type="checkbox"]:focus + label::before {
    background: #c5c2c2;
    box-shadow: 0 0 0.4em #2987ce;
}

.toggle [type="checkbox"] + label:hover::after,
.toggle [type="checkbox"]:focus + label::after {
    box-shadow: 0 1px 2px 0 rgba(34,34,34,.35), inset 0 0 0 1px rgba(34,34,34,.35);
}

/* CHECKED */
.toggle [type="checkbox"]:checked + label::before {
    background-color: $primary;
}
.toggle [type="checkbox"]:checked + label::after {
    left: 1.65rem;
}

/* DISABLED */

.toggle [type="checkbox"][disabled] + label:hover {
    cursor: default;
}

.toggle [type="checkbox"][disabled] + label:hover::before,
.toggle [type="checkbox"][disabled]:focus + label::before {
    box-shadow: none;
    background: rgba(255,255,255,.2);
}

.toggle [type="checkbox"][disabled]:checked + label::before {
    background-color: $primary-disabled;
}

.toggle [type="checkbox"][disabled] + label::after {
    background-color: #f3f3f3;
}

.toggle [type="checkbox"][disabled] + label:hover::after,
.toggle [type="checkbox"][disabled]:focus + label::after {
    box-shadow: 0 1px 2px 0 rgba(34,34,34,.15), inset 0 0 0 1px rgba(34,34,34,.25);
}

/* LAYOUT */
.toggle-with-labels {
    display:flex;
}

.toggle-with-labels .toggle-outer-label {
    padding-right:10px;
}

.toggle-with-labels .toggle + .toggle-outer-label {
    padding-left:10px;
}

