/*
--------------------------------------------------------------------------------------- FEATURES

------------------------------------------------------------------------------------------------
*/

.feature-grid {
    margin-bottom:10px;
}

.feature-grid.feature-grid-thin {
    margin-bottom:0;
}

.feature-item {
    display: block;
    margin-bottom: 40px;
    max-width: 1020px;
}

.feature-item.feature-item-bordered {
    border:1px solid $white;
}

.feature-item .feature-item-inner {
    //padding: 20px 10px 10px 0;
    @media screen and (min-width: $break-tablet) {
        //padding: 20px 24px 10px 0;
    }
}

.feature-item.feature-item-bordered .feature-item-inner {
    //padding: 20px 10px 10px 20px;
    @media screen and (min-width: $break-tablet) {
        //padding: 20px 24px 10px 24px;
    }
}

.feature-item .feature-item-inner ul {
    margin-left: 0;
    padding-left: 20px;
    @include fontSize(15px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(16px);
    }
}


.feature-item .feature-item-inner p {
    @include fontSize(16px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(18px);
    }
}

.feature-item .feature-item-inner ul:last-child,
.feature-item .feature-item-inner p:last-child,
.feature-item .feature-item-inner dl:last-child {
    margin-bottom:0;
}

.feature-item {
    border:1px solid $primary;
    padding:16px;
    @media screen and (min-width: $break-tablet) {//  720/16
        // padding:20px;
    }
}

.feature-item h2 {
    position: relative;
    margin-bottom: 22px;
}

.feature-item h2 {
    // padding-top:18px;
}

.feature-item h2::before {
    // content:'';
    // background-color: $primary;
    // //
    // position: absolute;
    // top:0;
    // width:60px;
    // height:2px;
    // @media screen and (min-width: $break-tablet) {
    //     width:74px;
    //     height:3px;
    // }
    
}

.feature-item.feature-split {
    background-color: transparent;
    max-width:none;
}

.feature-item-img {
    margin-bottom: 30px;
}

.feature-item-img img {
    display: block;
    margin:0;
}

.feature-item .hr {
    background-color:transparent;
}

.feature-item-cta {
    padding-top:16px;
}

@media screen and (min-width: $break-tablet) {

    .feature-grid {
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .feature-item {
        margin-bottom: 30px;
    }

    .feature-grid .feature-item {
        -ms-flex-preferred-size: span(6, $grid-login);
        flex-basis: span(6, $grid-login);
        max-width: span(6, $grid-login);
    }

    .feature-grid.feature-grid-thin .feature-item {
        -ms-flex-preferred-size: span(6, $grid-tablet);
        flex-basis: span(6, $grid-tablet);
        max-width: span(6, $grid-tablet);
    }

    .feature-grid.feature-equal-height .feature-item {
        display: flex;
    }

    .feature-grid.feature-equal-height .feature-item-inner {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .feature-grid.feature-equal-height .feature-item-content {
        flex: 1 0 auto;
    }

    // CENTERED CONTENT

    .feature-grid-centered .feature-item {
        -ms-flex-preferred-size: span(6, $grid-login);
        flex-basis: span(6, $grid-login);
        max-width: span(6, $grid-login);
    }

    .feature-grid-centered {
        display:flex;
        justify-content: space-between;
        //justify-content: center;
    }

    .feature-grid-centered .feature-item {
        /*max-width: 400px;
        margin:0 100px;*/
        text-align: center;
    }

    .feature-grid-centered .feature-item-inner {
        max-width: 400px;
        padding-right:0;
        margin-left: auto;
        margin-right: auto;
    }

    .feature-grid-centered .feature-item p {
        padding-right:0;
    }

    .feature-grid-centered .feature-item h2::before {
        left:50%;
        margin-left: -37px;
    }

}

@media screen and (min-width: $break-desktop) {


    .feature-grid .feature-item {
        -ms-flex-preferred-size: span(4, $grid-login);
        flex-basis: span(4, $grid-login);
        max-width: span(4, $grid-login);
    }

    .feature-grid.feature-grid-thin .feature-item {
        -ms-flex-preferred-size: span(4, $grid-tablet);
        flex-basis: span(4, $grid-tablet);
        max-width: span(4, $grid-tablet);
    }

    .feature-grid.feature-grid-half .feature-item {
        -ms-flex-preferred-size: span(6, $grid-login);
        flex-basis: span(6, $grid-login);
        max-width: span(6, $grid-login);
    }

}

@media screen and (min-width: $break-desktop-wide) {

    .feature-grid.feature-grid-spaced .feature-item {
        -ms-flex-preferred-size: span(4, $grid-login);
        flex-basis: span(4, $grid-login);
        max-width: span(4, $grid-login);
    }

}
   

/* SPLIT FEATURES */

@media screen and (min-width: $break-tablet) {

    .feature-item.feature-split {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
    }

    .feature-item.feature-split.feature-split-align-top {
        align-items: flex-start;
    }

    .feature-item.feature-split .feature-item-inner {
        -ms-flex-preferred-size: span(6, $grid-login);
        flex-basis: span(6, $grid-login);
        max-width: span(6, $grid-login);
        //
        padding-top:0;
        padding-right:0;
    }

    


    .feature-item.feature-split .feature-item-inner p:last-child {
        margin-bottom:0;
    }

    .feature-item.feature-split .feature-item-img {
        -ms-flex-preferred-size: span(6, $grid-login);
        flex-basis: span(6, $grid-login);
        max-width: span(6, $grid-login);
        //
        margin-bottom: 0;
        
    }

    .feature-item.feature-split .feature-item-img img {
        display: block;
    }

    .feature-item.feature-split.feature-split-reversed {
        flex-direction: row-reverse;
    }

    .feature-item.feature-split.feature-split-reversed .feature-item-img {
        padding-right:0;
    }

}


@media screen and (min-width: $break-desktop) {

    .feature-item-inner-content {
        max-width: 440px;
    }

    .feature-item.feature-split .feature-item-img {
        //padding-right:50px;
        
    }
    .feature-item.feature-split.feature-split-reversed .feature-item-img {
        //padding-left:50px;
    }

}

// PS SPECIAL FEATURE
.feature-item.feature-item-ps {
    background-color: #1b1b1c;
    border:0;
    margin-bottom: 0;
}

.feature-item.feature-split.feature-split-align-top.feature-item-ps {
    align-items: stretch;
}

.feature-item.feature-item-ps,
.feature-item.feature-item-ps h1,
.feature-item.feature-item-ps h2,
.feature-item.feature-item-ps h3,
.feature-item.feature-item-ps h4 {
    color:#92845a;
}

.feature-item.feature-item-ps .feature-item-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.feature-item-ps-content {
    display: flex;
    align-items: flex-end;
}

.feature-item-ps-content img {
    max-width: 90px;
    display: block;
    margin:0 10px 6px 0;
}
