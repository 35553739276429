/*
--------------------------------------------------------------------------------------- DROPDOWN

------------------------------------------------------------------------------------------------
*/

.dropdown-toggle {
    position: relative;
}


.dropdown-toggle .dropdown-menu,
.dropdown-toggle .dropdown-block {
    display:none;
    text-align: left;
    margin:0;
    padding: 0;
    list-style:none;
    position:absolute;
    top:100%;
    left:0;
    width:260px;
    //border:1px solid $border-grey-pale;
    background-color:$white;
    z-index:$z-dropdown;
}

.dropdown-toggle.dropdown-open .dropdown-menu,
.dropdown-toggle.dropdown-open .dropdown-block {
    display:block;
    // Chrome bug where dropdown would be cutoff by flex container
    transform: translate3d(0, 0, 0);
}

.dropdown-toggle .dropdown-menu li {width:100%; border-bottom:1px solid $border-grey-pale; padding-bottom: 0; position:relative;}
.dropdown-toggle .dropdown-menu li:last-child {border-bottom:none;}

.dropdown-toggle .dropdown-menu li a {line-height: 42px; color: $text-dark; text-decoration: none; display: block; padding: 0 16px;}
.dropdown-toggle .dropdown-menu li a:hover {background-color:$bg-grey-paler;}

/* ----------------- Dropdown block ------ */

.dropdown-toggle .dropdown-block {
    width:304px;
    @media screen and (min-width: $break-tablet) {//  720/16
        width:200px;
    }
    border-radius: 5px;
    padding: 10px 10px 6px 10px;
    box-shadow: 0 4px 6px 0px rgba(0,0,0,0.2);
}


.dropdown-toggle .dropdown-block.dropdown-block-wide {
    width:304px;
    @media screen and (min-width: $break-tablet) {//  720/16
        width:310px;
    }
}

.dropdown-toggle .dropdown-block p {@include fontSize(14px); margin-bottom: 10px;}
.dropdown-toggle .dropdown-block h4 {margin-bottom: 10px;}

.dropdown-toggle .dropdown-block:before {
  content: ""; position: absolute; width: 0; height: 0; border-style: solid; border-color: transparent; border-top: 0;
}

.dropdown-toggle .dropdown-block:before {top: -5px; right: 8px; border-bottom-color: $white; border-width: 5px;}
//.dropdown-block:after {top: -11px; right: 6px; border-bottom-color: $bg-grey-pale; border-width: 11px;}
