/*
 *
 *   Summernote Custom Styles
 *   Version: 1.0.2
 *
 */


/*
------------------------------------------------------------------------------------- SUMMERNOTE

------------------------------------------------------------------------------------------------
*/


//-------- Ember overrides for summernote

// Change font paths

@font-face {
	font-family:"summernote";
	src:url("../fonts/summernote.eot?ad8d7e2d177d2473aecd9b35d16211fb");
	src:url("../fonts/summernote.eot?#iefix") format("embedded-opentype"),
	url("../fonts/summernote.woff?ad8d7e2d177d2473aecd9b35d16211fb") format("woff"),
	url("../fonts/summernote.ttf?ad8d7e2d177d2473aecd9b35d16211fb") format("truetype")
}

.note-toolbar,
.note-editable,
.note-statusbar{
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.note-editor,
.note-editor.note-frame {
	border:none;
}

.note-editor.panel,
.note-editor .note-statusbar,
.note-editor.note-frame .note-statusbar {
	border-radius: 2px;
}

.note-editor .note-toolbar {
    background-color: #f9f9f9;
    border-left: 1px solid $input-border;
    border-right: 1px solid $input-border;
    border-top: 1px solid $input-border;
    border-bottom: 1px solid $input-border;
}

.note-editor .note-statusbar {
    background-color: #f5f5f5;
    border-left: 1px solid $input-border;
    border-right: 1px solid $input-border;
    border-bottom: 1px solid $input-border;
}

.note-editor .note-editable {
    border-left: 1px solid $input-border;
    border-right: 1px solid $input-border;
}


.note-editor .note-statusbar .note-resizebar {
  border-color: $input-border;
  padding-top:2px;
  height: 12px;
}

// Active highlight
.note-active .note-toolbar {border-top-color:$primary; border-left-color:$primary; border-right-color:$primary;}
.note-active .note-editable {border-left-color:$primary; border-right-color:$primary;}
.note-active .note-statusbar {border-bottom-color:$primary; border-left-color:$primary; border-right-color:$primary;}


// Image widths within summernote
.content-img-quarter {width:25%;}
.content-img-half {width:50%;}
.content-img-full {width:100%;}

// Images within summernote
.note-editable img {margin-right: 10px; margin-bottom: 10px;}
.note-editable img.pull-right {margin-right: 0; margin-left: 10px;}

// Image links within summernote
.note-editable .summernote-img-link > img {border:2px solid $primary; display:block;}
