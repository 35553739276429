/*
--------------------------------------------------------------------------------- RATING (STARS)

------------------------------------------------------------------------------------------------
*/


/* STAR SVG */

@function svg-star-fill($fill-color) {

    @return '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"><path fill="' + $fill-color + '" d="M98.531,42c2.509-2.446,1.726-4.859-1.742-5.363l-30.381-4.415L52.819,4.693 c-1.549-3.143-4.088-3.143-5.639,0L33.593,32.223L3.212,36.637C-0.255,37.141-1.039,39.555,1.47,42L23.454,63.43l-5.19,30.258 c-0.592,3.453,1.46,4.945,4.562,3.314L50,82.717l27.176,14.285c3.1,1.631,5.153,0.139,4.56-3.314L76.547,63.43L98.531,42z"/></svg>';

}


@function svg-star-outline($fill-color) {

    @return '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"><path fill="' + $fill-color + '" d="M50,12.538l11.028,22.341l1.396,2.828l3.122,0.454L90.2,41.743l-17.842,17.39L70.1,61.335l0.533,3.108 l4.211,24.555L52.792,77.406L50,75.938l-2.792,1.468L25.156,88.999l4.212-24.555l0.533-3.109l-2.259-2.202l-17.84-17.39 l24.654-3.583l3.122-0.454l1.396-2.828L50,12.538 M50.001,2.336c-1.022,0-2.045,0.786-2.82,2.357L33.593,32.223L3.212,36.637 C-0.255,37.141-1.039,39.555,1.47,42L23.454,63.43l-5.19,30.258c-0.429,2.503,0.531,3.976,2.273,3.976 c0.662,0,1.436-0.212,2.29-0.661L50,82.717l27.176,14.285c0.853,0.449,1.627,0.661,2.288,0.661c1.741,0,2.702-1.473,2.271-3.976 L76.547,63.43L98.531,42c2.509-2.446,1.726-4.859-1.742-5.363l-30.381-4.415L52.819,4.693C52.045,3.122,51.023,2.336,50.001,2.336 L50.001,2.336z"/></svg>';

}


$star-fill-yellow: svg-star-fill(#fab83a);
$star-outline-yellow: svg-star-outline(#fab83a);

.star-test {width:100px; height: 100px; background-image: url(svg-encode($star-fill-yellow));}
.star-test-outline {width:100px; height: 100px; background-image: url(svg-encode($star-outline-yellow));}

/*.star:before {
    opacity:0.5;
    background-position: 0 50%;
    background-image: url(svg-encode($star-yellow));
}*/



/* ----- */

.rating {
    float:left;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
   follow these rules. Every browser that supports :checked also supports :not(), so
   it doesn’t make the test unnecessarily selective */


.rating:not(:checked) > input {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.rating:not(:checked) > label {
    float:right;
    width:1em;
    padding:0 .1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    line-height:1.2;
    position: relative;
    text-indent: 100%;
}

.rating:not(:checked) > label:before {
    content: '';
    display: block;
    width:10px;
    height:10px;
    background-color: red;
    position: absolute;
    top:0;
    left:0;
}

.rating > input:checked ~ label:before {
    background-color: green;
}

.rating:hover > input:checked ~ label:before {
    background-color: blue;
}

.rating:not(:checked) > label:hover:before,
.rating:not(:checked) > label:hover ~ label:before {
    background-color: pink;
}

.rating > input:checked + label:hover:before,
.rating > input:checked + label:hover ~ label:before,
.rating > input:checked ~ label:hover:before,
.rating > input:checked ~ label:hover ~ label:before,
.rating > label:hover ~ input:checked ~ label:before {
    background-color: pink;
}

.rating > label:active {
    position:relative;
    top:2px;
    left:2px;
}

/* ------- STATIC STARS ------ */
/*
.rating-static {unicode-bidi: bidi-override; direction: rtl; float:left; margin-left:-2px; margin-right:0;}
.rating-static > span {display: inline-block; position: relative; width: 16px; height: 16px; overflow:hidden; text-indent:-100%;}
.rating-static > span:before {
  color:$input-border;
  display:block;
  text-indent:0;
  font:normal normal normal 14px/1 FontAwesome;
  text-rendering:auto;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  transform:translate(0, 0);
  content: "\f006";
  width:16px; height:16px; position:absolute; top:0; left:0;
}


// I don't know how this works but it does:
.rating-static.rating-5 > span:before,
.rating-static.rating-4 > span + span:before,
.rating-static.rating-3 > span + span + span:before,
.rating-static.rating-2 > span + span + span + span:before,
.rating-static.rating-1 > span + span + span + span + span:before {
  content: "\f005";
  //color:$yellow;
}

.rating-bronze.rating-static.rating-5 > span:before,
.rating-bronze.rating-static.rating-4 > span + span:before,
.rating-bronze.rating-static.rating-3 > span + span + span:before,
.rating-bronze.rating-static.rating-2 > span + span + span + span:before,
.rating-bronze.rating-static.rating-1 > span + span + span + span + span:before {
  color:#a76321;
}

td.col-rating {
  width:120px;
}
*/


/* ------- SELECTABLE STARS ------ */
/*
// Show basic radio buttons for old browsers
.rating {display: inline-block;}
.rating label {margin-left:10px; margin-right:4px; }

.rating label,
.rating input {
  float: right;
}

.rating input { margin-top:10px;}

.form-group .rating {padding-top:6px;}

// Show stars for newer browsers
// :not(:checked) is a filter, so that browsers that don’t support :checked don’t follow these rules.

.rating:not(:checked) {font-weight: normal; font-style: normal;  overflow: hidden; font-size: 0; position: relative;}

.rating:not(:checked) input {float: right; width: 24px; height: 24px; padding: 0; margin: 0 0 0 -24px; opacity: 0;}

.rating:not(:checked) label {position: relative; float: right; display: block; width: 24px; height: 24px; margin:0; }

.rating:not(:checked) label:before {
  //color:$input-border;
  display:inline-block;
  //font:normal normal normal 18px/1 FontAwesome;
  //text-rendering:auto;
  //-webkit-font-smoothing:antialiased;
  //-moz-osx-font-smoothing:grayscale;
  //transform:translate(0, 0);
  //content: "\f006";
  content: '';
  width:20px; height:18px; position:absolute; top:2px; left:2px; background-color: red;
}

.rating:not(:checked) label:hover {cursor: pointer;}

.rating:not(:checked):hover label:before,
.rating:not(:checked):hover input:checked ~ label:before{
    background-color: green;
}

.rating:not(:checked):hover label:hover:before,
.rating:not(:checked):hover label:hover ~ label:before{
    background-color: pink;
}

.rating:not(:checked) input:checked ~ label:before {
    //content: "\f005";
    //color:$yellow;
    background-color: orange;
}
*/
