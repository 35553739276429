/*
----------------------------------------------------------------------------------- TESTIMONIALS

------------------------------------------------------------------------------------------------
*/

.testimonial-container {
    border:1px solid $primary;
}

.testimonial {
    text-align: center;
    padding:20px;
}

.testimonial-content {
    /*max-width: 660px;*/
    margin:0 auto;
    @include clrfix;
    margin-bottom: 0;
}


.testimonial-content p {
    @include fontSize(20px);
    @media screen and (min-width: $break-tablet) {
        @include fontSize(24px);
    }
    line-height: 1.1;
    @include font-smoothing(on);
}

.testimonial-author {
    //padding-bottom: 40px;
}
.testimonial-author p {
    @include fontSize(12px);
    @media screen and (min-width: $break-tablet) {
        @include fontSize(18px);
    }
}

/* GLIDE CAROUSEL THEMING */

.glide__bullets {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);
}

.glide__bullet {
    width: 64px;
    height: 44px;
    padding: 0;
    cursor: pointer;
    line-height: 0;
    //
    position: relative;
    //
    margin: 0 0.25em;
    //
    background: transparent;
    outline: none;
    border: none;
}

.glide__bullet::before {
    content:'';
    width:38px;
    height:4px;
    //
    background:#737373;
    // 
    position: absolute;
    top:50%;
    left:50%;
    margin:-2px 0 0 -19px;
    //
    transition: background-color 0.2s linear;
}

.glide__bullet--active::before {
    background:$primary;
}