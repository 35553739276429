/*
------------------------------------------------------------------------------------ SITE FOOTER

------------------------------------------------------------------------------------------------
*/


/* MAIN FOOTER */

.footer {position:relative;}

.footer::before {
    content:'';
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:1px;
    background: $gradient-fallback-gold;
    background: $gradient-gold;
}

.footer-grid-row {
    @media screen and (min-width: $break-tablet) {//  720/16
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.footer .logo {
    display:block;
    max-width:160px;
    margin:0 0 30px 0;
    float:none;
    //
    @media screen and (min-width: $break-tablet) {//  720/16
        max-width:220px; 
    }
}

.footer .logo img {
    display:block;
    margin:0;
}

.footer-main {
    background-color: #000;
    padding:30px 0;
}

@media screen and (min-width: $break-tablet) {//  720/16
    .footer-main {padding:40px 0;}
}


.footer-nav {
    list-style-type: none;
    margin-left:0;
    padding-left:0;
    //@include font-smoothing(off);
}

.footer-nav li {
    padding: 0;
}

.footer-nav a {
    color:$white;
    font-weight: $font-light;
    display: block;
    padding: 0 0 4px 0;
}

.footer-nav a:hover {
    text-decoration: underline;
}

@media screen and (min-width: $break-tablet) {//  720/16
    .footer-nav {margin-bottom:0;}
}


.footer .social-nav {
    margin-left: -10px;
}

.footer .social-nav a {
    color:$white;
}

.footer .social-nav a:hover {
    color:$link-primary-hover;
}



/* SUB FOOTER */

.footer-sub {
    background-color: #000; 
    color:$white; 
    padding:16px 0; 
    box-shadow: 0 500px 0px 500px #000;
}

.footer-sub p.copyright {
    @include fontSize(14px); 
    margin:0;
}

/* Gap for cookie consent */
.footer-sub {
    padding-bottom:180px;
    @media screen and (min-width: $break-tablet) {//  720/16
        padding-bottom:76px;
    }
}

.cookie-dismissed .footer-sub {
    padding-bottom:16px;
}
