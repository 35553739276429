/*
------------------------------------------------------------------------------ CUSTOM CHECKBOXES

------------------------------------------------------------------------------------------------
*/

//-------- Custom CSS Checkboxes
// :not checked excludes old browsers

.custom-check,
.custom-radio {
    padding-top:7px; 
    padding-left: 23px;
    display: flex;
}

.check-small,
.radio-small {
    padding-left: 14px;
}

.form-horizontal .custom-check,
.form-horizontal .custom-radio {
    padding-top: 7px;
}

.custom-check input[type=checkbox]:checked,
.custom-check input[type=checkbox]:not(checked),
.custom-radio input[type=radio]:checked,
.custom-radio input[type=radio]:not(checked),

.form-inline .custom-check input[type=checkbox]:checked,
.form-inline .custom-check input[type=checkbox]:not(checked),
.form-inline .custom-radio input[type=radio]:checked,
.form-inline .custom-radio input[type=radio]:not(checked) {
    width: 22px;
    height: 22px;
    margin: 0 0 0 -22px;
    padding: 0;
    opacity: 0;
    position: absolute;
    z-index: 1;
    cursor: pointer;
}

.check-small input[type=checkbox]:checked,
.check-small input[type=checkbox]:not(checked),
.radio-small input[type=radio]:checked,
.radio-small input[type=radio]:not(checked),

.form-inline .check-small input[type=checkbox]:checked,
.form-inline .check-small input[type=checkbox]:not(checked),
.form-inline .radio-small input[type=radio]:checked,
.form-inline .radio-small input[type=radio]:not(checked)  {
    width: 14px;
    height: 14px;
    margin-left:-14px;
}


.custom-check label,
.custom-radio label,
.form-inline .custom-check label,
.form-inline .custom-radio label {
    display: block;
    position: relative;
    padding-left: 8px;
    line-height: 22px;
    text-align: left;
}

.custom-check label:before, .custom-radio label:before {

    content: '';
    display: inline-block;

    background-image: url('../img/checkbox-sprite-green-alt.png');
    background-repeat: no-repeat;

    @media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
        background-image: url('../img/retina/checkbox-sprite-green-alt.png');
        background-size: 182px 150px;
    }

    width: 22px;
    height: 22px;
    margin-right: 10px;
    position: absolute;
    left: -23px;
    top: -2px;
}

.modal-body .custom-check label:before, 
.modal-body .custom-radio label:before {

    background-image: url('../img/checkbox-sprite-green.png');
    
    @media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
        background-image: url('../img/retina/checkbox-sprite-green.png');
    }
}

.check-small label:before,
.radio-small label:before {
    width: 14px; 
    height: 14px; 
    left: -14px; 
    top: 2px;
}

.check-small-label label {
    @include fontSize(15px);
}


// Default
.custom-check label:before {
    background-position: 0 0;
}
.custom-radio label:before {
    background-position: 0 -50px;
}

.check-small label:before {
    background-position: 0 -100px;
}
.radio-small label:before {
    background-position: 0 -125px;
}


// Error
.error .custom-check label:before {
    background-position: -130px 0;
}
.error .custom-radio label:before {
    background-position: -130px -50px;
}

.error .check-small label:before {
    background-position: -130px -100px;
}
.error .radio-small label:before {
    background-position: -130px -125px;
}

/*.custom-check label.error:before,
.form-horizontal .custom-check label.error:before,
.custom-radio label.error:before,
.form-horizontal .custom-radio label.error:before {
  display:none;
}

.custom-check label.error,
.form-horizontal .custom-check label.error,
.custom-radio label.error,
.form-horizontal .custom-radio label.error {
  padding-left:8px;
  display:block;
}*/

.custom-check + label.error,
.custom-radio + label.error {
    width:100%; clear:both;
}


// :Hover
.custom-check input[type=checkbox]:hover + label:before {
    background-position: -26px 0;
}
.custom-radio input[type=radio]:hover + label:before {
    background-position: -26px -50px;
}

.check-small input[type=checkbox]:hover + label:before {
    background-position: -26px -100px;
}
.radio-small input[type=radio]:hover + label:before {
    background-position: -26px -125px;
}

// :focus
.custom-check input[type=checkbox]:focus + label:before {
    background-position: -156px 0;
}
.custom-radio input[type=radio]:focus + label:before {
    background-position: -156px -50px;
}

.check-small input[type=checkbox]:focus + label:before {
    background-position: -156px -100px;
}
.radio-small input[type=radio]:focus + label:before {
    background-position: -156px -125px;
}

// :checked, :checked + :hover, , :checked + :focus
.custom-check input[type=checkbox]:checked + label:before,
.custom-check input[type=checkbox]:checked:hover + label:before,
.custom-check input[type=checkbox]:checked:focus + label:before  {
    background-position: -52px 0;
}
.custom-radio input[type=radio]:checked + label:before,
.custom-radio input[type=radio]:checked:hover + label:before,
.custom-radio input[type=radio]:checked:focus + label:before  {
    background-position: -52px -50px;
}

.check-small input[type=checkbox]:checked + label:before,
.check-small input[type=checkbox]:checked:hover + label:before,
.check-small input[type=checkbox]:checked:focus + label:before  {
    background-position: -52px -100px;
}
.radio-small input[type=radio]:checked + label:before,
.radio-small input[type=radio]:checked:hover + label:before,
.radio-small input[type=radio]:checked:focus + label:before {
    background-position: -52px -125px;
}




// :disabled

.custom-check input[type=checkbox]:disabled + label:before {
    background-position: -78px 0;
}
.custom-radio input[type=radio]:disabled + label:before {
    background-position: -78px -50px;
}

.check-small input[type=checkbox]:disabled + label:before {
    background-position: -78px -100px;
}
.radio-small input[type=radio]:disabled + label:before {
    background-position: -78px -125px;
}

// :disabled + checked

.custom-check input[type=checkbox]:disabled:checked + label:before {
    background-position: -104px 0;
}
.custom-radio input[type=radio]:disabled:checked + label:before {
    background-position: -104px -50px;
}

.check-small input[type=checkbox]:disabled:checked + label:before {
    background-position: -104px -100px;
}
.radio-small input[type=radio]:disabled:checked + label:before {
    background-position: -104px -125px;
}
