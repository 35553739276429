/*
---------------------------------------------------------------------------------- PORTFOLIO GRID

------------------------------------------------------------------------------------------------
*/

@page {
    //size: A4;
    margin: 0;
}
/*
@media print {
    html, body {
        margin: 0;
        width: 210mm;
        height: 297mm;
    } 
}
*/

.portfolio-logo {
    max-width: 320px;
    width: 220px;
    //background-color: pink;
    //float:left;
    margin: 0 auto;
}

.portfolio-header {
    //display: flex;
    //align-items: center;
    @include clrfix;
    margin-bottom: 10px;
    
}

.portfolio-header .portfolio-contact{
    //margin-left:auto;
    //float:right;
    //text-align: right;
}

.portfolio-header h1, 
.portfolio-header h2{
    margin-bottom: 6px;
    position: relative;
}

.portfolio-header h1 span {
    display: inline-block;
    background-color: white;
    padding:0 10px;
    position: relative;
    z-index: 2;
}

.portfolio-header h1::before,
.portfolio-header h1::after {
    content:'';
    position: absolute;
    top:30%; 
    right:0;
    left:0;
    height:1px;
    background-color: $primary;
}

.portfolio-header h1::after {
    top:60%; 
    z-index: 1;
}
  

.portfolio-page {
    padding: 16mm;
    background-color: #fff;
    text-align: center;
}

.portfolio-page,
.portfolio-page h1, 
.portfolio-page h2, 
.portfolio-page h3,
.portfolio-page h4,
.portfolio-page h5 {
    color:#000;
}

.portfolio-page-inner {
    //border:1px solid #cecfcf;
    //padding: 5mm;
}


.portfolio-grid {
    //display:flex;
    //flex-wrap: wrap;
    width:100%;
    padding-top: 20px;
    @include clrfix;
   // background-color: skyblue;
}

.portfolio-grid .portfolio-grid-item {
    /*
    -ms-flex-preferred-size: span(6, $grid-portfolio);
    flex-basis: span(6, $grid-portfolio);
    max-width: span(6, $grid-portfolio);
    //
    margin-right:gutter($grid-portfolio);
    */
   // -ms-flex-preferred-size: 48%;
    //flex-basis: 48%;
    //max-width: 48%;
    width: 50%;
    //
    float:left;
    margin:0 0 10px 0;
    //padding-right:20px;
    //
    page-break-inside: avoid;
    //background-color: pink;
}

.portfolio-grid .portfolio-grid-item-inner {
    padding: 0 20px;
    width: 270px;
    margin:0 auto;
}




.portfolio-grid .portfolio-grid-item:nth-child(2n+1){
    clear:left;
}

.portfolio-grid.portfolio-grid-3 .portfolio-grid-item {
    width: 33.3333%;
}

.portfolio-grid.portfolio-grid-3 .portfolio-grid-item:nth-child(2n+1){
    clear:none;
}

.portfolio-grid.portfolio-grid-3 .portfolio-grid-item:nth-child(3n+1){
    clear:left;
}

.portfolio-grid-item h5 {
    margin-bottom:10px;
    margin-top:10px;
}

.portfolio-grid-item p {
    margin-bottom:8px;
}

.portfolio-grid-img {
    margin:0 auto;
    width: 260px;
}

.portfolio-grid-img img {
    display:block;
    margin:0;
    width:100%;
    height: auto;
}


.portfolio-grid-img.ratio-square {
    width:100%;
    padding-top:80%;
    position: relative;
}

.portfolio-grid-img.ratio-square img {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    object-fit: cover;
    width:100%;
    height:100%;
}

.portfolio-footer {
    padding-top:10px;
}