/*
 *
 *   Multi Select Column Field
 *   Version: 1.0.1
 *
 */

/*
----------------------------------------------------------------------- MULTI SELECT COLUMN FIELD

------------------------------------------------------------------------------------------------
*/


/* CROSS */
@function svg-msc-cross($fill-color) {

    @return '<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"><g><path fill="' + $fill-color + '" d="M8.26 1.9 6 4.16 3.74 1.9a.5.5 0 0 0-.71 0L1.9 3.03a.5.5 0 0 0 0 .71L4.16 6 1.9 8.26a.5.5 0 0 0 0 .71l1.13 1.13c.19.19.51.19.71 0L6 7.84l2.26 2.26c.19.19.51.19.71 0l1.13-1.13a.51.51 0 0 0 0-.71L7.84 6l2.26-2.26a.51.51 0 0 0 0-.71L8.97 1.9a.5.5 0 0 0-.71 0z"/></g></svg>';

}

/* TICK */
@function svg-msc-tick($fill-color) {

    @return '<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"><g><path fill="' + $fill-color + '" d="M9.87 1.6 4.63 6.83l-2.5-2.5a.54.54 0 0 0-.76 0L.16 5.54a.54.54 0 0 0 0 .76l4.1 4.1c.21.21.55.21.76 0l6.83-6.83a.54.54 0 0 0 0-.76L10.63 1.6a.54.54 0 0 0-.76 0z"/></g></svg>';

}


/* LAYOUT */
.msc-selection {
    border: 1px solid $input-border;
    position: relative;
	@include clrfix;
}

.msc-choices {
    border-bottom: 1px solid $white;
}

@media screen and (min-width: $break-tablet) {//  720/16

    .msc-selection {
        display: flex;
        flex-wrap: wrap;
    }

    .msc-intro,
    .msc-values,
    .msc-choices,
    .msc-values {
        flex: 1;
        flex-basis: 50%;
        max-width: 50%;
        width: 0; 
    }

    .msc-intro {
       // order:1;
    }

    .msc-values {
       // order:2;
    }

    .msc-choices {
       // order:3;
        border-bottom: 0;
    }

    .msc-values {
        //order:4;
    }

}

.multi-select-columns .msc-choices,
.multi-select-columns .msc-values {
	//width:50%;
	//float:left;
}

/* ELEMENTS */

.multi-select-columns .msc-choices {
    border-right: $input-border solid 1px;
    background-color: #000;
}

.multi-select-columns .msc-scroll {
    height: 140px;
    overflow: auto;
    clear: both;
}

.multi-select-columns.msc-tall .msc-scroll {
    height: 300px;
}

.multi-select-columns ul {
    list-style: none;
	margin: 0;
    padding: 0;
    position: relative;
}

.multi-select-columns li {
    display: block;
    margin:0;
    padding:0;
    position: relative;
    z-index: 0;
    transition: background-color 200ms linear;
}

.multi-select-columns li > div{
    border-bottom: 1px solid $input-border;
}

.multi-select-columns li span {
    transition: opacity 200ms linear;
}

.msc-choices li > div {
    padding:6px 12px; 
    display: flex;
    align-items: center;
}

.msc-choices.animate li > div {
    transition: padding 0.2s ease-in-out;
}


.msc-choices li .msc-item-thumb-container {
    width:80px;
    margin:0 10px 0 0;
    transition: opacity 0.2s linear;
}

.msc-choices li .msc-item-thumb-ratio {
    width:100%;
    padding-top:100%;
    position: relative;
}
 
.msc-choices li .msc-item-thumb-container img {
    width:80px;
    height:auto;
    display: block;
    margin:0;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}


.msc-values li > div {
    padding-right: 46px;
    background-color: #000;
}



/* TICK */
@function svg-msc-hamburger($fill-color) {

    @return '<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"><g><path fill="' + $fill-color + '" d="M12 2.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1c0-.27.22-.5.5-.5h11c.27 0 .5.23.5.5v1zM12 6.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1c0-.28.22-.5.5-.5h11c.27 0 .5.22.5.5v1zM12 10.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1c0-.27.22-.5.5-.5h11c.27 0 .5.23.5.5v1z"/></g></svg>';

}


$bg-msc-hamburger: svg-msc-hamburger($white);

.msc-values .drag-link {
    padding:6px 0 6px 34px; 
    display: block; 
    z-index: 1; 
    position:relative;
    display: flex;
    align-items: center;
}

.msc-values .drag-link::before {
    content:'';
    width:12px;
    height:12px;
    position: absolute;
    top:50%;
    left:10px;
    margin-top:-6px;
    background: url(svg-encode($bg-msc-hamburger)) 0 0 no-repeat;
}



.msc-values .drag-link .msc-item-thumb-container {
    width:80px;
    margin:0 10px 0 0;
    transition: opacity 0.2s linear;
}

.msc-values li .msc-item-thumb-ratio {
    width:100%;
    padding-top:100%;
    position: relative;
}
 
.msc-values .drag-link .msc-item-thumb-container img {
    width:80px;
    height:auto;
    display: block;
    margin:0;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}


.msc-values .drag-link:hover {
    cursor : ns-resize;
}

.msc-values a {color:inherit;}

// Disabled / Selected
.multi-select-columns li.rel-field-disabled span {opacity:0.5;}
.multi-select-columns li.rel-field-disabled .msc-item-thumb-container {opacity:0.5;}

.msc-choices li.rel-field-disabled > div {padding-left: 32px;}
.msc-choices li:not(.rel-field-disabled):hover {cursor: pointer;}

.msc-choices li .msc-disabled-tick {
    display: block;
    width:12px;
    height:12px;
    //
    position: absolute; 
    top:50%; 
    left:12px; 
    margin-top: -6px;
    //
    opacity:0; 
    transform: translate(-6px,0);   
}

.msc-choices.animate li .msc-disabled-tick {
    transition: opacity 0.2s linear, transform 0.2s ease-in-out;
}

$bg-msc-tick: svg-msc-tick($white);

.msc-choices li.rel-field-disabled .msc-disabled-tick {
    opacity:0.5; 
    transform: translate(0,0);
    background: url(svg-encode($bg-msc-tick)) 0 0 no-repeat;
}

// Show / hide
.msc-values li {
    background-color: #000;
    transition: opacity 200ms linear,
        max-height 400ms cubic-bezier(0, 1, 0.5, 1),
        background-color 200ms linear;
    display:block;

}

.msc-values li.show-anim {opacity:1;}
.msc-values li.hide-anim {opacity:0;}
.msc-values li.highlight {background-color: #5d4713;}

// Actions
.multi-select-columns .msc-actions {
    position: absolute; 
    top:0; 
    right:0; 
    bottom:1px; 
    z-index: 2;
}

.multi-select-columns .msc-actions button {
    background: transparent;
    border:0;
    outline:0;
    margin:0;
    padding:0;
    width:40px;
    height:100%;
    @include icon-hide;
    cursor: pointer;
}


.multi-select-columns .msc-actions button::before {
    content:'';
    width:12px; 
    height:12px;
    position: absolute;
    top:50%;
    left:50%;
    margin: -6px 0 0 -6px;
    opacity:0.4;
    transition: opacity 0.15s linear;
}

.multi-select-columns .msc-actions button:hover::before {
    opacity:1;
}

$bg-msc-cross: svg-msc-cross($white);

.multi-select-columns .msc-actions button.msc-remove::before {
    background: url(svg-encode($bg-msc-cross)) 0 0 no-repeat;
}

/* INTRO */

.msc-intro {
    padding: 10px;
}

.msc-intro p {
    margin:0;
}

.msc-intro-values.msc-intro-values-desktop,
.msc-intro-values.msc-intro-values-desktop p,
.msc-intro-values.msc-intro-values-mobile p {
    display:none;
}


.show-drag-message .msc-intro-values.msc-intro-values-mobile p,
.show-drag-message .msc-intro-values.msc-intro-values-desktop p {
    display:block;
}

@media screen and (min-width: $break-tablet) {//  720/16
    .msc-intro-values.msc-intro-values-mobile {
        display:none;
    }
    .msc-intro-values.msc-intro-values-desktop {
        display:block;
    }
    
}

/*

.msc-intro-values {
    opacity:0;
}

.msc-intro-values.animate {
    transition: opacity 0.2s linear;
}

.msc-intro-values.show {
    opacity:1;
}*/

/* DRAGGING */

.msc-scroll li.draggable-source--is-dragging {
    background-color: #114160;
}

.msc-scroll li.draggable-source--is-dragging > div {
    opacity:0;
}


/* WARNINGS */
.msc-message {
    margin-top:10px;
    display: none;
}

.modal-body .msc-message {
    color:$text-main;
}

.multi-select-columns.show-error .msc-message,
.msc-message.show {
    display: block;
}



