/*
------------------------------------------------------------------------------------------- GRID

------------------------------------------------------------------------------------------------
*/

.grid-row {
    display: block;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.grid-row.grid-row-sm {
    display: flex;
}

.grid-row.grid-row-bordered {
    border: 1px solid $primary;
    padding: 16px;
    @media screen and (min-width: $break-tablet) {//  720px/16
        padding: 30px;
    }
}

@media screen and (min-width: $break-landscape) {//  496px/16

    .grid-row {
        display: flex;
    }

    .grid-row.grid-row-tablet {
        display: block;
    }

    // Create the 12 column classes
    @for $span from 1 through length(susy-get('columns')) {
        .col-#{$span} {
            -ms-flex-preferred-size: span(6);
            flex-basis: span(6);
            max-width: span(6);
        }

        .grid-row.grid-row-tablet .col-#{$span} {
            max-width: none;
        }

    }


}

@media screen and (min-width: $break-tablet) {//  720px/16
    .grid-row.grid-row-tablet {
        display: flex;
    }
    // Create the 12 column classes
    @for $span from 1 through length(susy-get('columns')) {
        .col-#{$span} {
            -ms-flex-preferred-size: span($span, $grid-tablet);
            flex-basis: span($span, $grid-tablet);
            max-width: span($span, $grid-tablet);
        }
    }

    @for $span from 1 through length(susy-get('columns')) {
        .grid-row.grid-row-wide-gutter .col-#{$span} {
            -ms-flex-preferred-size: span($span, $grid-wide-gutter);
            flex-basis: span($span, $grid-wide-gutter);
            max-width: span($span, $grid-wide-gutter);
        }
    }

    @for $span from 1 through length(susy-get('columns')) {
        .grid-row.grid-row-wider-gutter .col-#{$span} {
            -ms-flex-preferred-size: span($span, $grid-wider-gutter);
            flex-basis: span($span, $grid-wider-gutter);
            max-width: span($span, $grid-wider-gutter);
        }
    }

}


/* FOR TESTING */
.col-label {
    position: relative;
    box-sizing: border-box;
    min-height: 30px;
    padding:5px 0;
    margin-bottom: 30px;
    background: #007FFF;
    overflow: hidden;
    text-align: center;
    color: #fff;
}




/* MAX WIDTH COLS */
.max-col-3 {max-width: 312px;}
.max-col-6 {max-width: 610px;}
.max-col-7 {max-width: 760px;}
.max-col-8 {max-width: 824px;}
.max-col-10 {max-width: 1028px;}
