/*
--------------------------------------------------------------------------- POPUP WINDOW STYLING

------------------------------------------------------------------------------------------------
*/


.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.modal-title {
    margin-bottom: 0;
    // Make modal title small on mobiile
    @media screen and (max-width: $break-landscape) {
        @include fontSize(15px);
    }
}


.modal-body {
    padding: 20px 15px;
    @media screen and (min-width: $break-tablet) {//  720/16
        padding-left: 30px;
        padding-right: 30px;
    }
    position: relative;
}

.modal-footer {
    padding: 15px;
    @media screen and (min-width: $break-tablet) {//  720/16
        padding-left: 30px;
        padding-right: 30px;
    }
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.modal-footer .btn {margin-bottom: 0; margin-right: 14px;}
.modal-footer .btn:last-child {margin-right: 0;}

/* MODAL SIZES */

.mfp-modal.mfp-modal-thinner,
.mfp-modal.modal-thinner {
    max-width: 380px;
}

.mfp-modal.mfp-modal-thin,
.mfp-modal.modal-thin {
    max-width: 480px;
}

.mfp-modal.mfp-modal-wide,
.mfp-modal.modal-wide {
    max-width: 780px;
    //max-width: 860px;
}

.mfp-modal.mfp-modal-wider,
.mfp-modal.modal-wider {
    max-width: 1100px;
}

.mfp-modal.mfp-modal-widest,
.mfp-modal.modal-widest {
    max-width: 1252px;
}



/* MODIFYERS */

.mfp-content .modal-white .modal-body {
    background-color: $white;
}

.mfp-content .modal-white .modal-footer {
    border: 0;
    padding-top: 0;
}

.modal-left-btns .modal-footer {
    text-align: left;
}

.mfp-content .mfp-modal.modal-rev {
    background-color: $bg-grey-palest;
}

.mfp-content .modal-rev .modal-body {
    background-color: $white;
}

/* COLOURS */

.modal-body p,
.modal-body h1, 
.modal-body h2, 
.modal-body h3, 
.modal-body h4, 
.modal-body h5, 
.modal-body h6,
.modal-body .h-1, 
.modal-body .h-2, 
.modal-body .h-3, 
.modal-body .h-4, 
.modal-body .h-5, 
.modal-body .h-6 {
    color:$text-main;
}
