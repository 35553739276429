/*
--------------------------------------------------------------------------------- CHOSEN SELECTS

------------------------------------------------------------------------------------------------
*/

// SITE SPECIFIC CHOSEN OVERRIDES


/* CONTAINER */
.chosen-container {/*margin-bottom:10px;*/ @include fontSize(16px); @include font-smoothing(on);}


/* DISPLAY AREA */

.chosen-container-single .chosen-single {
    //height: 38px;
    //line-height:38px;
    padding: 7px 0 8px 14px;

    font-weight:normal;

    background:$form-input-bg-alt;
    color: $white;
    border: 1px solid $form-input-border-alt;

    overflow:visible;
    border-radius: 0;

    box-shadow: none;
}

.modal-body .chosen-container-single .chosen-single {
    background-color: $form-input-bg;
    color: $text-main;
    border-color: $form-input-border;
}

.chosen-container-single .chosen-default {color: $text-placeholder;}

.chosen-container-single .chosen-single:hover,
.chosen-container-active.chosen-with-drop .chosen-single {
    background-color:$form-input-focus-bg-alt; 
    background-image: none; 
    box-shadow: none;
}

.modal-body .chosen-container-single .chosen-single:hover,
.modal-body .chosen-container-active.chosen-with-drop .chosen-single {
    background-color:$form-input-focus-bg; 
}


.chosen-container-multi .chosen-choices {
    background-color:$form-input-bg-alt;
    color: $white;
    border: 1px solid $form-input-border-alt;
    @include fontSize(14px);
    padding-left:0;
    border-radius:0;
    background-image: none;
}

.modal-body .chosen-container-multi .chosen-choices {
    background-color:$form-input-bg;
    color: $text-pale;
    border-color: $form-input-border;
}


.chosen-container-active .chosen-single,
.chosen-container-active .chosen-choices {
    border: 1px solid $primary;
    box-shadow: none;
}

.chosen-container-single .chosen-single div {display: block; height: 100%; position: absolute; top: 0; right: 0; width: 18px;}

.chosen-container-single .chosen-single div b {background-position: 0 10px;}
.chosen-container-active.chosen-with-drop .chosen-single div b {background-position: -18px 10px;}

.chosen-container-single .chosen-single abbr {top: 13px; right:24px;}

//.chosen-container-active.chosen-with-drop .chosen-single div:before {/*background-position:$pos-chosen-arrow-down;*/}
//.chosen-container-single .chosen-single div {width:35px; height:auto;}
//.chosen-container-single .chosen-single div:before {@include icon(); width:16px; height:11px; position:absolute; top:21px; left:9px;}
//.chosen-container-single .chosen-single div b {display:none;}

//.chosen-container-single .chosen-single abbr {@include icon(); width:18px; height:18px; top: 15px; right: 36px; /*background-position:$pos-chosen-cross;*/ opacity:0.5;}
//.chosen-container-single .chosen-single abbr:hover {/*background-position: $pos-chosen-cross;*/ opacity:1;}


/* PLACE HOLDER */
.chosen-container-single .chosen-single span {padding-top:1px; line-height:20px;}


.chosen-container-single .chosen-search input[type=text] { color:$white; }
.modal-body .chosen-container-single .chosen-search input[type=text] { color:$text-main; }

/* RESULTS LIST */
.chosen-container .chosen-drop {
    background-color:$form-input-bg-alt; 
    box-shadow: none;
}

.modal-body .chosen-container .chosen-drop {
    background-color:$form-input-bg; 
}

.chosen-container-active .chosen-drop {border: 1px solid $form-input-border-alt;}

.modal-body .chosen-container-active .chosen-drop {border-color: $form-input-border;}

.chosen-container .chosen-results {
    margin: 0; 
    padding: 0; 
    @include fontSize(16px); 
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
    color:$white;
}

.modal-body .chosen-container .chosen-results {
    color:$text-dark;
}

.chosen-container .chosen-results li {padding: 8px 8px;}
.chosen-container .chosen-results li.highlighted {background-color: $primary; background-image: none; }

.chosen-container .chosen-results li.group-result {font-weight:$font-bold; color:$primary;}

/* SEARCH FIELD */
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    height: 34px; box-shadow: none; font-family: inherit; padding-left: 14px; @include fontSize(16px);
}


/* DISABLED */
.chosen-container-single.chosen-disabled .chosen-single abbr,
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  /*background-position:$pos-chosen-cross;*/ opacity:0;
}

.chosen-container-single.chosen-disabled .chosen-single:hover {background-color:$white;}

/* MULTI SELECT */
.chosen-container-multi .chosen-choices li.search-choice {margin-top: 5px; margin-left:8px; padding: 6px 20px 6px 8px; box-shadow: none;}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {right:4px; top:8px; /*background: 0;*/}

//.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {@include icon(); width:16px; height:16px; top: 10px; right: 36px; /*background-position:$pos-chosen-cross;*/ opacity:0.5;}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover:before {/*background-position: $pos-chosen-cross;*/ opacity:1;}
