/*
--------------------------------------------------------------------------------- COOKIE WARNING

------------------------------------------------------------------------------------------------
*/

.cookie-warning-wrapper {z-index: 9001; position: relative;}

@keyframes cookieSlideUp {
    from {
        transform:translateY(100%);
    }
    to {
        transform:translateY(0);
    }
}

@keyframes cookieSlideDown {
    from {
        transform:translateY(0);
    }
    to {
        transform:translateY(100%);
    }
}

.cookie-warning {
    padding: 12px 15px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: #3a3a3a;
    background: rgba(58,58,58,.95);
    color: $white;
    //
    transform: translateY(100%);
    animation: cookieSlideUp 0.6s 1s ease-out;
    animation-fill-mode: forwards; //retain the computed values set by the last keyframe
}


.cookie-warning-message {margin-bottom: 10px;}
.cookie-warning p {margin-bottom: 0;}

.cookie-warning-action {min-width: 100px;}
.cookie-warning .btn {margin:0;}


@media screen and (min-width: $break-tablet) {
    .cookie-warning-grid {
        display: flex;
        align-items: center
    }

    .cookie-warning-message {margin-bottom: 0;}

    .cookie-warning-action {text-align: right;}
}

/* DISMISSED */
/* convoluted way of reversing the animation */
.cookie-dismissed-animate .cookie-warning {
    animation: none;
    transform: translateY(0);
    transition: transform 0.3s ease-out;
}
.cookie-dismissed-animate-down .cookie-warning {
    transform: translateY(100%);
}
.cookie-dismissed .cookie-warning-wrapper {display:none;}