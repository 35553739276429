/*
---------------------------------------------------------------------------------------- BANNERS

------------------------------------------------------------------------------------------------
*/

.banner {
    
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    // 
    min-height:240px;
    @media screen and (min-width: $break-landscape) {
        min-height:360px;
    }
    @media screen and (min-width: $break-tablet) {
        min-height:480px;
    }
    @media screen and (min-width: $break-desktop) {
        min-height:520px;
    }
}


.banner.banner-tall {
    @media screen and (min-width: $break-desktop-wide) {
        min-height:700px;
    }
}

