/*
------------------------------------------------------------------------------------ DEPARTMENTS

------------------------------------------------------------------------------------------------
*/

.department-item {
    margin: 0 auto 30px auto;
    max-width: 300px;
}

.department-grid-bordered .department-item {
    border:1px solid $primary;
    padding: 20px;
}

@media screen and (min-width: $break-tablet) {

    .department-grid {
        display:flex;
        flex-wrap: wrap;
        gap: gutter($grid-tablet);
    }

    .department-item {
        margin: 0 0 30px 0;
        max-width: none;
    }

    .department-grid .department-item {
        -ms-flex-preferred-size: span(6, $grid-login);
        flex-basis: span(6, $grid-login);
        max-width: span(6, $grid-login);
    }

}

@media screen and (min-width: $break-desktop) {

    .department-grid .department-item {
        -ms-flex-preferred-size: span(4, $grid-login);
        flex-basis: span(4, $grid-login);
        max-width: span(4, $grid-login);
    }

}

.department-item-img {
    display:block;
    border-top: 3px solid $primary;
}

.department-item .social-nav {
    margin-left:-12px;
}




$bg-large-plus-grey: svg-large-plus(#808080);

.icon-large-plus {
    width:32px;
    height:32px;
    position: relative;
}

.icon-large-plus::before {
    content:'';
    display: block;
    width:32px;
    height:32px;
    position: absolute;
    top:0;
    left:0;
    background: url(svg-encode($bg-large-plus-grey)) 0 0 no-repeat;
}

.department-item-title {
    display: flex;
}

.department-item-title .icon-large-plus {
    margin-left:20px;
    min-width:32px;
}


/* Internal vacancy */

.internal-vacancy-title {
    text-align: center;
}

.internal-vacancy-title a {
    color:#fff;
}

.internal-vacancy-description {
    text-align: center;
}

.internal-vacancy-btn {
    text-align: center;
    text-transform: uppercase;

    @include fontSize(16px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(26px);
    }

}

.department-grid-equal-height .department-item-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 40px;
    position: relative;
}

.department-grid-equal-height .internal-vacancy-btn {
    position: absolute;
    bottom: 0;
    width: 100%;
}
/*

<div class="department-grid">

            <div class="department-item">
                <div class="department-item-img">
                    <img src="/build/img/temp-department-01.jpg" alt="" />
                </div>
                <div class="department-item-content">
                    <div class="department-item-title">
                       <h3>Sara Duncan<br>Yachting</h3>
                    </div>
                    <div class="department-item-contact">
                        <p class="text-larger"><a href="#" class="link-blank">name@crewandconcierge.com</a><br><a href="#" class="link-blank">+44 7825678527</a></p>

                        <ul class="social-nav">

                            <li>
                                <a href="https://www.instagram.com/CrewandConcierge_" target="_blank" class="icon-social social-in" title="Instagram" itemprop="sameAs">
                                    <div class="icon-ratio">
                                        <svg class="svg-icon" aria-label="Instagram Icon">
                                            <title>Find us on Instagram</title>
                                            <use xlink:href="#social-instagram"></use>
                                        </svg>
                                    </div>
                                </a>
                            </li>
                            
                            <li>
                                <a href="https://www.linkedin.com/company/crew-concierge-ltd" target="_blank" class="icon-social social-li" title="Linkedin" itemprop="sameAs">
                                    <div class="icon-ratio">
                                        <svg class="svg-icon" aria-label="Linkedin Icon">
                                            <title>Find us on Linkedin</title>
                                            <use xlink:href="#social-linkedin"></use>
                                        </svg>
                                    </div>
                                </a>
                            </li>
                                
                        </ul>

                    </div>
                </div>

            </div>

        </div>

/*

.department-item {
    @include clrfix;
    margin-bottom:20px;
}

.department-item-img {
    max-width: 220px;
    margin-bottom:20px;
}

.department-item-img img {
    display:block;
    margin:0;
}


@media screen and (min-width: $break-tablet) {//  720/16
    .department-item-grid {
        display:flex;
    }

    .department-item-img {
        margin-right: 30px;
    }
}*/