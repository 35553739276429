/*
--------------------------------------------------------------------- TRUNCATED BLOCK OF CONTENT

------------------------------------------------------------------------------------------------
*/

.js .truncated-block {
    position: relative;
}

.js .truncated-block-inner {
    overflow:hidden;
    max-height:480px;
    position: relative;
    //
    transition: max-height 0.4s ease-in-out;
    padding-top:1px;
}

.js .truncated-block-inner.truncated-block-tall {
    transition: max-height 0.8s ease-in-out;
}

.js .truncated-block-inner.truncated-block-no-height {
    max-height:0;
}

.js .truncated-block-inner.truncated-block-inner-fade:after {
    content:'';
    width:100%;
    height:200px;
    //
    position: absolute;
    bottom:0;
    left:0;
    z-index: 2;
    //
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 95%);
    //
    opacity:1;
    transition: opacity 0.4s linear;
}

/* .truncated-block-inner HAS TO BE DIRECT CHILD OF .truncated-block */

.js .truncated-block.animating > .truncated-block-inner.truncated-block-inner-fade:after{
    opacity:0;
}
.js .truncated-block.active > .truncated-block-inner.truncated-block-inner-fade:after{
    display:none;
}

.js .truncated-block.active > .truncated-block-inner {
    overflow: visible;
}

.js .truncated-block.animating.active > .truncated-block-inner,
.js .truncated-block.animating.active > .truncated-block-inner.truncated-block-no-height {
    max-height:none;
}


/* BUTTON */

.truncated-block-btn {display: none;}

.js .truncated-block-btn {
    display: inline-block;
    opacity:1;
    transition: opacity 0.4s linear;
}

.js .truncated-block-no-hide .truncated-block-btn{
    position: absolute;
    bottom:-60px;
    left:0;
    z-index: 3;
}

.js .truncated-block-no-hide.animating .truncated-block-btn,
.js .truncated-block-no-hide-relative.animating .truncated-block-btn {
    opacity:0;
}
.js .truncated-block-no-hide.active .truncated-block-btn,
.js .truncated-block-no-hide-relative.active .truncated-block-btn {
    display:none;
}


/* CHEVRON */

.btn.chevron-btn {
    position: relative;
    padding-left:18px!important;
}

$chevron-up-primary: svg-chevron-up($primary);
$chevron-down-primary: svg-chevron-down($primary);

.btn.chevron-btn::before {
    content:'';
    width:12px;
    height:7px;
    background: url(svg-encode($chevron-down-primary)) 0 0 no-repeat;
    position: absolute;
    top:50%;
    left:0;
    transform: translateY(-50%);
}

.btn.chevron-btn.open::before {
    background: url(svg-encode($chevron-up-primary)) 0 0 no-repeat;
}