/*
 *
 *   File Upload Async
 *   Version: 1.0.3 - [CC-335] - sync with MP
 *
 */

/*
----------------------------------------------------------------- IMAGE ASYNC FILE UPLOAD FIELDS

------------------------------------------------------------------------------------------------
*/


.async-file-upload {
    border:2px dashed $placeholder-border;
    background-color: $form-input-bg-alt;
    text-align: center;
    padding: 16px 20px 20px 20px;
    //margin-bottom: 4px;
}

.modal-body .async-file-upload{
    background-color: $placeholder-bg; 
}

.form-group.error .async-file-upload {
    border-color: $danger;
}

.async-file-upload .btn {margin:0;}

.async-file-upload.dz-drag-hover {
    border:2px solid $primary;
    position: relative;
}

.async-file-upload.dz-drag-hover:before {
    content:'';
    z-index: 2;
    position: absolute;
    top:2px;
    right:2px;
    bottom:2px;
    left:2px;
    background-color: $form-input-focus-bg-alt;
}

.modal-body .async-file-upload.dz-drag-hover:before {
    background-color: $placeholder-bg;
}

/* UPLOAD ARROW */
@function svg-upload-arrow($fill-color) {
    @return '<svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px"><path fill="' + $fill-color + '" d="M38,40H2c-1.104,0-2-0.896-2-2V26c0-1.104,0.896-2,2-2s2,0.896,2,2v10h32V26c0-1.104,0.896-2,2-2s2,0.896,2,2v12 C40,39.104,39.104,40,38,40z"/><path fill="' + $fill-color + '" d="M31.414,10.586l-10-10c-0.78-0.781-2.048-0.781-2.828,0l-10,10c-0.781,0.781-0.781,2.047,0,2.828 C8.976,13.805,9.488,14,10,14s1.024-0.195,1.414-0.586L18,6.828V28c0,1.104,0.896,2,2,2s2-0.896,2-2V6.828l6.586,6.586 C28.977,13.805,29.488,14,30,14s1.023-0.195,1.414-0.586C32.195,12.633,32.195,11.367,31.414,10.586z"/></svg>';
}

$bg-upload: svg-upload-arrow($placeholder-border);

.async-file-upload.dz-drag-hover:after {
    content:'';
    z-index: 3;
    //
    display:block;
    width:40px;
    height: 40px;
    //
    position: absolute;
    top:50%;
    left:50%;
    margin:-20px 0 0 -20px;
    background-image: url(svg-encode($bg-upload));
    background-repeat: no-repeat;
    background-position: 0 0;
}

.dz-started {

}

.async-file-upload-status {
    margin-bottom:12px;
}

.async-file-upload-status p {
    margin-bottom:0;
    color:$white;
}

.modal-body .async-file-upload-status p {
    margin-bottom:0;
    color:$text-dark;
}


.async-file-upload-error {
    color:$danger;
}

/*
-------------------------------------------------------------------------------------- RESPONSES

------------------------------------------------------------------------------------------------
*/

.async-file-upload-thumbs {
    display: flex;
    flex-wrap: wrap;
}

.async-file-upload-item {
    max-width: 160px;
    position: relative;
    margin-right:40px;
    margin-bottom:16px;
    transition: opacity 0.2s linear;
    opacity:0;
}

.async-file-upload-thumbs .async-file-upload-item:last-child {
    margin-bottom:0;
}

.async-file-upload-item.fade-in {
    opacity:1;
}

.async-file-upload-item.async-file-upload-item-img {
    min-height:80px;
    max-width: 360px;
}

.async-file-upload-item.async-file-upload-item-video {
    max-width: 640px;
}

.async-file-upload-item img{
    display: block;
    margin:0;
}

.async-file-upload-item p,
.async-file-upload-item .async-file-upload-item-img-container,
.async-file-upload-item .ms-video-player, 
.async-file-upload-item .ms-audio-player {
   margin-bottom: 6px;
}

.async-file-upload-item-icon {
    width:160px;
    height:80px;
    background:$bg-grey-paler;
    border:1px solid $bg-grey-pale;
    position: relative;
}

.modal-body .async-file-upload-item-icon {
    background:$white;
}

.async-file-upload-item-icon .file-icon {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.async-file-upload-item-icon img {
    margin:0;
    display:block;
}

.async-file-upload-item-del {
    border:0;
    outline:0;
    background: transparent;
    //
    width: 40px;
    height: 40px;
    //
    padding:0;
    margin:0;
    position: absolute;
    top:-12px;
    right:-32px;
    //
    @include icon-hide;
    cursor: pointer;
}

$thick-cross-text: svg-thick-cross($text-main);
$thick-cross-white: svg-thick-cross($white);

.async-file-upload-item-del::before {
    content:'';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    position: absolute;
    top:10px;
    left:10px;
}

.async-file-upload-item-del:hover::before {
    background-color:$danger;
}

.async-file-upload-item-del::after {
    content:'';
    display: block;
    width:12px;
    height:12px;
    //
    position: absolute;
    top:14px;
    left:14px;
    //
    background: url(svg-encode($thick-cross-white)) 0 0 no-repeat;
}

.modal-body .async-file-upload-item-del::after {
    background: url(svg-encode($thick-cross-text)) 0 0 no-repeat;
}

.async-file-upload-item-del:hover::after {
    background: url(svg-encode($thick-cross-white)) 0 0 no-repeat;
}


/* STYLING FOR AVATAR */
.async-file-upload-wrapper.async-file-upload-avatar .async-file-upload-item.async-file-upload-item-img {
    max-width: 180px;
}

.async-file-upload-wrapper.async-file-upload-avatar .async-file-upload-item-img-container {
    position:relative;
}

.async-file-upload-wrapper.async-file-upload-avatar .async-file-upload-item-img-container {
    border-radius: 50%;
    overflow: hidden;
}

.async-file-upload-wrapper.async-file-upload-avatar .async-file-upload-filename {
    display:none;
}
/*
$avatar-outline-white: svg-avatar-outline($white);

.async-file-upload-wrapper.async-file-upload-avatar .async-file-upload-item-img-container::after {
    content:'';
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background: url(svg-encode($avatar-outline-white)) 0 0 no-repeat;
    background-size: contain;
    opacity:0.95;
}
*/