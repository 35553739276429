/*
 *
 *   PROGRESS BARS
 *   Version: 1.0.2 - [CCS-198] - multiple uploads
 *
 */


.progress {
    display: flex;
    height: 6px;
    overflow: hidden;
    //
    background-color: #eff0f1;
    //
    border-radius: 3px;
    //
    position: relative;
    //
    overflow: visible;
}

.progress:before {
    content:'';
    position: absolute;
    top: 0;
    right:0;
    bottom:0;
    left:0;
    //
    border: 1px solid #dcdcdc;
    border-radius: 3px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    //
    background-color: $btn-primary;
    border: 1px solid $btn-primary-border;
    //
    //border-top-left-radius: 3px;
    //border-bottom-left-radius: 3px;
    border-radius: 3px;
    //
    transition: width .6s ease;
    position:relative;
    //
    overflow: visible;
}
