/*
------------------------------------------------------------------------------------------------
  _site_buttons.scss - BUTTON ELEMENT BASE STYLES
------------------------------------------------------------------------------------------------
  Created:  01-03-2016
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/

/* DEFAULT */

.btn {
    @include fontSize(14px);


    line-height: strip-unit( calc(22px / 16px) );

    font-weight:normal;
    @include font-smoothing(off);

    padding: 7px 14px 7px 14px;

    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(16px);
        padding: 8px 20px 6px 20px;
    }
    
    margin: 0 20px 16px 0;

    background-color: $btn-default-bg;
    border:1px solid $btn-default-border;

    color: $text-main;

    display: inline-block;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    cursor: pointer;
    outline:none;
    text-decoration:none;
    text-transform:uppercase;

    border-radius: 0;

    position:relative;
    opacity:1;

    transition: background-color 100ms linear, color 100ms linear, border-color 100ms linear, padding 200ms ease-in-out, opacity 100ms linear;

    letter-spacing: 0.75px;
    white-space: normal; // make multiline

    @include appearance(none);

}

.btn:hover,
.btn:focus {
    background-color:$btn-default-hover-bg; 
    border-color:$btn-default-hover-border; 
    color: $text-main; 
    text-decoration: none;
}

.btn:active {
    outline: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .08);
}

.btn.active {
    background-color: $btn-primary; border-color:$btn-primary-border; color:$white; text-decoration: none;
    box-shadow: inset 0px 0px 4px 1px rgba(0,0,0,0.05);
}

.btn:focus, 
.btn:active:focus, 
.btn.active:focus, 
.btn.focus, 
.btn:active.focus, 
.btn.active.focus {
    box-shadow: 0 0 0 1px $black, 0 0 0 2.5px $btn-default-border;
}

.btn:disabled,
.btn.disabled,
.btn:disabled:hover,
.btn.disabled:hover,
a:hover .btn.disabled {
    background-color: #eeeeed; border-color:#eeeeed; color:#7a7a7a; cursor: not-allowed; pointer-events: none;
    opacity:0.5;
}


.btn.active:disabled,
.btn.active.disabled,
.btn.active:disabled:hover,
.btn.active.disabled:hover,
a:hover .btn.disabled {
    background-color: $bg-grey-mid; border-color:$bg-grey-mid; color:$white; 
}


// Fix firefox button height
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}


/* COLOUR - PRIMARY */

.btn-primary {background-color: $btn-primary; border-color:$btn-primary-border; color:$black; /*text-shadow: 1px 1px 1px #dd900a;*/}

.btn-primary:hover,
.btn-primary:focus {
    background-color:$primary-hover; border-color:$primary-hover; color:$black; text-decoration:none;
}

.btn-primary:disabled,
.btn-primary.disabled,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus {
    background-color:$primary-disabled; border-color:$primary-disabled; color:$black;
}

.btn-primary:focus,
.btn-primary:active:focus {
    box-shadow: 0 0 0 1px $black, 0 0 0 2.5px $btn-primary;
}


/* COLOUR - SECONDARY */

.btn-secondary {background-color:$secondary; border-color:$secondary; color:$white;}

.btn-secondary:hover,
.btn-secondary:focus {
    background-color:$secondary-hover; border-color:$secondary-hover; color:$white;
}


.btn-secondary:disabled,
.btn-secondary.disabled,
.btn-secondary:disabled:hover,
.btn-secondary:disabled:focus,
.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus {
    background-color:$secondary-disabled; border-color:$secondary-disabled; color: $white;
}




/* COLOUR - DANGER */

.btn-danger {background-color:$btn-danger; border-color:$btn-danger; color:$white;}

.btn-danger:hover,
.btn-danger:focus {
    background-color:$btn-danger-hover; border-color:$btn-danger-hover; color:$white;
}

.btn-danger:disabled,
.btn-danger.disabled,
.btn-danger:disabled:hover,
.btn-danger:disabled:focus,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus {
    background-color:#ec988b; border-color:#ec988b; color: $white;
}

.btn-danger:focus,
.btn-danger:active:focus {
    box-shadow: 0 0 0 1px $white, 0 0 0 2.5px $btn-danger;
}


/* TEXT ONLY */

.btn-link,
.btn-link:hover,
.btn-link:focus {
    background-color: transparent;
    border-color: transparent;
    color: $link-primary;
    letter-spacing: 0;
    padding-left: 0;
    padding-right: 0;
}

.btn-link:focus,
.btn-link:active,
.btn-link:active:focus {
    box-shadow: none;
}

/* OUTLINED */
.btn-outline {background-color:transparent;}
.btn-outline.btn-rev {border-color:$white; color:$white;}
.btn-outline.btn-rev:hover {background-color:$white; color:$text-main;}

/* OUTLINED - PRIMARY */

.btn-primary.btn-outline {background-color:transparent; border-color:$primary; color:$primary;}

.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus {
    background-color:$primary; border-color:$primary; color:$white;
}



/* OUTLINED - SECONDARY */

.btn-secondary.btn-outline {background-color:transparent; border-color:$secondary; color:$secondary;}

.btn-secondary.btn-outline:hover,
.btn-secondary.btn-outline:focus {
    background-color:$secondary; border-color:$secondary; color:$white;
}



/* REVERSED - PRIMARY */

.btn-primary.btn-rev {background-color:$white; border-color:$white; color:$primary;}

.btn-primary.btn-rev:hover,
.btn-primary.btn-rev:focus {
    background-color:transparent; border-color:$white; color:$white;
}

.btn-primary.btn-outline.btn-rev {background-color:transparent; border-color:$white; color:$white;}

.btn-primary.btn-outline.btn-rev:hover,
.btn-primary.btn-outline.btn-rev:focus {
    background-color:$white; border-color:$white; color:$primary;
}


/* REVERSED - SECONDARY */

.btn-secondary.btn-rev {background-color:$white; border-color:$white; color:$secondary;}

.btn-secondary.btn-rev:hover,
.btn-secondary.btn-rev:focus {
    background-color:transparent; border-color:$white; color:$white;
}

.btn-secondary.btn-outline.btn-rev {background-color:transparent; border-color:$white; color:$white;}

.btn-secondary.btn-outline.btn-rev:hover,
.btn-secondary.btn-outline.btn-rev:focus {
    background-color:$white; border-color:$white; color:$secondary;
}


/* BUTTON SIZES */

.btn-sm {@include fontSize(13px); padding: 5px 13px 5px 13px;}
.btn-xs {@include fontSize(12px); padding: 4px 12px 4px 12px;}

@media screen and (min-width: $break-tablet) {//  720/16
    .btn-lg {
        @include fontSize(18px);
        padding: 9px 28px 9px 28px;
    }

    .btn-xl {
        @include fontSize(20px);
        padding: 10px 40px 12px 40px;
    }
}

.btn-block,
.btn-sm.btn-block {
    width:100%; text-align:center; padding-left: 4px; padding-right: 4px;
}

.btn-fixed {
    min-width: 255px;
    text-align: center;
}

/* BUTTON GROUP */

.btn-group {margin-bottom: 20px;}

.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group > .btn {
    margin:0;
    position: relative;
    flex: 0 1 auto;
    letter-spacing: 0;
}

.btn-group > .btn:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group .btn + .btn, {
    margin-left: -1px;
}

.js .btn-group-toggle > .btn input[type="radio"],
.js .btn-group-toggle > .btn-group > .btn input[type="radio"] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}




/* BUTTON WITH SVG ICON */
.btn .icon-ratio {
    float:left;
    margin-right: 8px;
    margin-top: 2px;
    width:16px;
    min-width:16px;
    height:16px;
}

.btn.btn-sm .icon-ratio {
    margin-top: 2px;
    width:14px;
    min-width:14px;
    height:14px;
}

.btn .icon-ratio.icon-ratio-eye {
    width:20px;
    min-width:20px;
}

.btn .icon-ratio.icon-ratio-calendar {
    margin-top: 1px;
    margin-right: 10px;
}


/* BUTTON NEXT ICON */
.btn.btn-next {
    position: relative;
    padding-right: 34px;
}

.btn.btn-next::after {
    content:'';
    width: 8px;
    height: 12px;
    //
    position: absolute;
    top:50%;
    margin-top:-6px;
    right:16px;
    //
    transition: transform 0.1s ease-out;
    //
    $bg-chevron-right: svg-chevron-right($black);
    background-image: url(svg-encode($bg-chevron-right));
}

.btn.btn-next:hover::after {
    transform: translateX(2px);
}

.btn.btn-lg.btn-next {
    @media screen and (min-width: $break-tablet) {//  720px/16
        padding-right: 46px;
    }
}

.btn.btn-lg.btn-next::after {
    @media screen and (min-width: $break-tablet) {//  720px/16
        right:26px;
    }
}

/* BUTTON PREV ICON */

.btn.btn-prev {
    position: relative;
    padding-left: 36px;
}

.btn.btn-prev::after {
    content:'';
    width: 6px;
    height: 11px;
    //
    position: absolute;
    top:12px;
    left:20px;
    //
    transition: transform 0.1s ease-out;
    //
    $bg-sm-triangle-left: svg-sm-triangle-left($white);
    background-image: url(svg-encode($bg-sm-triangle-left));
}

.btn.btn-prev:hover::after {
    transform: translateX(-2px);
}

.btn.btn-lg.btn-prev {
    padding-left: 46px;
}

.btn.btn-lg.btn-prev::after {
    top:15px;
    left:30px;
}
