/*
 *
 *   Alerts
 *   Version: 1.0.6 [MS-509] - fix icon, margin jump
 *
 */


.alert {
    padding: 10px;
    @media print, screen and (min-width: $break-landscape) {
        padding: 15px 15px 13px 15px;
    }
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    @include fontSize(14px);
    @media screen and (min-width: $break-tablet) {
        @include fontSize(16px);
    }
    line-height: 18px;
    @include clrfix;
    position:relative;
}

.alert-row {display: flex;}

.alert p:last-child {margin-bottom: 0;}

.alert h1,
.alert h2,
.alert h3,
.alert h4 {
    margin-bottom: 8px;
}

.alert .alert-link {font-weight: $font-bold;}

.alert-success {
    color: $alert-success-content;
    background-color: $alert-success-bg;
    border-color: $alert-success-bg-dark;
}

.alert-success h1,
.alert-success h2,
.alert-success h3,
.alert-success h4,
.alert-success .alert-link {
    color: $alert-success-link;
}


.alert-info {
    color: $alert-info-content;
    background-color: $alert-info-bg;
    border-color: $alert-info-bg-dark;
}

.alert-info h1,
.alert-info h2,
.alert-info h3,
.alert-info h4,
.alert-info .alert-link {
    color: $alert-info-link;
}

.alert-warning {
    color: $alert-warning-content;
    background-color: $alert-warning-bg;
    border-color: $alert-warning-bg-dark;
}

.alert-warning h1,
.alert-warning h2,
.alert-warning h3,
.alert-warning h4,
.alert-warning .alert-link {
    color: $alert-warning-link;
}

.alert-danger {
    color: $alert-danger-content;
    background-color: $alert-danger-bg;
    border-color: $alert-danger-bg-dark;
}

.alert-danger h1,
.alert-danger h2,
.alert-danger h3,
.alert-danger h4,
.alert-danger .alert-link {
    color: $alert-danger-link;
}


// Close Button

.alert.alert-with-close {padding-right: 30px;}

.alert .alert-close {
    padding: 0 10px;
    cursor: pointer;
    background: transparent;
    border: 0;
    float: right;
    font-size: 25px;
    font-weight: bold;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    //
    position: absolute;
    top: 6px;
    right: 0;
    @media screen and (min-width: $break-landscape) {
        top: 12px;
        right: 4px;
    }
}

.alert .alert-close:hover,
.alert .alert-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5;
}



// Alert Icon
.alert .icon-ratio {margin-right:10px;}

.alert .svg-icon {display:none;}

.alert.alert-success .alert-icon-success {display: block;}
.alert.alert-danger .alert-icon-danger {display: block;}
.alert.alert-warning .alert-icon-warning {display: block;}
.alert.alert-info .alert-icon-info {display: block;}

.alert.alert-show-icon .svg-icon {
    display: block;
}

// ALERT ANIMATION
.alert-anim {
    transition: max-height 300ms ease-in-out;
    max-height: auto;
    @include clrfix;
}

.alert-anim .alert {transition: opacity 300ms linear;}

.alert-anim.alert-hidden {max-height:0; overflow: hidden;}
.alert-anim.alert-hidden .alert {opacity:0;}



// Fixed Alert (notification)

.alert.alert-fixed {
    width: 300px;
    padding: 12px 15px;
    display: inline-block;
    //
    //color: $text-main;
    //background-color: $bg-grey-pale;
    box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.095);
    //border: 1px solid transparent;
    //
    position: fixed;
    top: -150px;
    right: 15px;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    //border-radius: 2px;
    cursor: pointer;
    z-index: $z-floating-alert;
}

.alert.alert-fixed.show {
    opacity: 1;
}

.alert.alert-fixed .alert-close {
    top: 11px;
}

// Floating alert (full width)

.floating-alert {
    position: fixed;
    top: 94px;
    right: 0;
    left:0;
    z-index: $z-floating-alert;
    padding: 0 30px;
    //
    @media print, screen and (min-width: $break-tablet) {//  720px/16
        padding: 0 30px;
        top: 80px;
    }
    @media print, screen and (min-width: $break-desktop) {//  720px/16
        padding: 0 45px;
        top: 86px;
        transform: translateY(16px);
        transition: transform 0.2s ease-out;
    }
}

@media print, screen and (min-width: $break-desktop) {//  720px/16
    .float-header-scrolled .floating-alert {
        transform: translateY(0);
    }
}


.floating-alert .alert {
    margin: 0 auto;
    box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.095);
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 1214px;
    //
    @media print, screen and (min-width: $break-tablet) {//  720px/16
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.floating-alert.floating-alert-thin .alert {
    max-width: 860px;
}


.floating-alert .alert .alert-close {
    @media print, screen and (max-width: $break-tablet) {//  720px/16
        top:7px;
    }
}

// PRINT RESETS
@media print {
    .alert {
        display: none;
    }
}
