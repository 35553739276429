/*
----------------------------------------------------------------- HERO BLOCKS AND HERO CAROUSELS

------------------------------------------------------------------------------------------------
*/

.hero-img {
    display: block;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-decoration: none;
    background-color: $black;
    padding: 50px 0 0 0;
    @media screen and (min-width: $break-tablet) {//  720/16
        padding: 60px 0 0 0;
    }
    //
    overflow: hidden;
}

.hero-img.hero-img-overlay::after {
    content:'';
    display: block;
    background-color: #000;
    opacity: 0.4;
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    //
    z-index: 30;
}

.hero-img .row,
.hero-img .hero-nav-cta-row {
    position: relative;
    z-index: 500;
}

.hero-caption {max-width: 820px; }
.hero-caption-content {padding-bottom: 15px;}

.hero-caption-heading {
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
}

.hero-caption.centered {text-align: center; margin-left: auto; margin-right: auto;}

.hero-caption-heading h1 {
    margin:0;
    text-align: center;
}

.hero-caption-heading p {
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(24px);
    }
}

.hero-caption .cta .btn {margin-bottom: 0;}
.hero-caption .cta .btn:last-child {margin-right:0;}

.hero-img.hero-img-centered {text-align: center;}
.hero-img.hero-img-centered .hero-caption {margin: 0 auto;}

.hero-dark-bg,
.hero-dark-bg h1 {
    color:$white;
}

@media screen and (min-width: $break-tablet) {//  720/16
    .hero-img.hero-img-fixed-height {
        //padding: 30px 0;
    }

    .hero-img.hero-img-fixed-height .row {
        min-height: 380px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }

    .hero-img.hero-img-fixed-height.hero-img-fixed-height-short .row {
        min-height: 320px;
    }

    .hero-img.hero-img-fixed-height.hero-img-fixed-height-shorter .row {
        min-height: 320px;
    }

}

@media screen and (min-width: $break-desktop) {//  720/16

    .hero-img.hero-img-fixed-height .row {
        min-height: 540px;
    }

    .hero-img.hero-img-fixed-height.hero-img-fixed-height-short .row {
        min-height: 420px;
    }

    .hero-img.hero-img-fixed-height.hero-img-fixed-height-shorter .row {
        min-height: 320px;
    }
}

/* HERO CTA NAV */

.hero-nav-cta-row {
    display: flex;
    justify-content: center;
    //
    @include clrfix;
    //
    padding-bottom:20px;
    @media screen and (min-width: $break-tablet) {//  720/16
        padding-bottom:60px;
    }
}

.hero-nav-cta-link {
    @include fontSize(16px);
    //
    margin: 0 10px 20px 10px;
    padding:6px 20px 4px 20px;
    //
    //
    @media screen and (min-width: $break-landscape) {//  720/16
        margin: 0 15px 20px 15px;
    }
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(20px);
        //
        margin: 0 25px;
        padding:6px 10px 2px 10px;
        min-width: 245px;
        max-width: 245px;
    }
    @media screen and (min-width: $break-desktop) {//  720/16
        @include fontSize(22px);
    }
    //
    display: block;
    
    //
    font-weight: $font-light;
    //
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    //
    background-color: $black;
    color:$primary;
    border: 1px solid $primary;
    //
    transition: background-color 0.2s linear, color 0.1s linear;
    
}

/*.hero-nav-cta-link:hover {
    background-color: $bg-grey-pale;
    color:$text-main;
}*/

/* HOME PAGE HERO POSITIONING */

.hero-with-cta .hero-caption-heading {
    @include clrfix;
    //
    margin-bottom: 40px; 
    //
    @media screen and (min-width: $break-tablet) {//  720/16
        margin-bottom: 20px;
        transform: translateY(20px);
    }
    
}




/* FADING CAROUSEL */

.hero-img-carousel {
    list-style:none;
    margin:0;
    padding:0;
    //
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

.hero-img-carousel li {
    margin:0;
    padding:0;
    //
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    //
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    //
    transition:opacity 0.6s linear;
    opacity:0;
    display:none;
}

.hero-img-carousel li.show {
    display:block;
}

.hero-img-carousel li.fade-in {
    opacity:1;
}

/* VIDEO */



.hero-video-container.darken::after {
    content:'';
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    //
    background: $black;
    opacity: 0.4;
    //
    z-index: 2;
}

.hero-video {
    object-fit: cover;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    //
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    //
    z-index: 1;
}