/*
------------------------------------------------------------------------- SITE HEADER NAVIGATION

------------------------------------------------------------------------------------------------
*/


/* NAVIGATION WITHOUT JS */

.main-nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include fontSize(14px);
    background: $black;
    @media screen and (min-width: $break-tablet) {
        background: transparent;
        @include fontSize(13px);
    }
    @media screen and (min-width: $break-desktop) {
       // @include fontSize(14px);
    }
    //
    
}

.main-nav li {
    padding: 0;
    position: relative;
}

.main-nav a {
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 12px;
    display: block;
    position: relative;
    border-bottom: 1px solid #38424b;
    //text-transform: uppercase;
    @include font-smoothing(on);
    font-family: $font-family-medium;
    line-height: 1.1;
    //
    transition: background-color 0.05s linear;
}



.main-nav a:hover,
.main-nav a.active {
    color: $white;
}

.main-nav ul ul {padding-bottom: 0;}
.main-nav ul ul a {padding-left: 25px;}

.main-nav > ul > li:last-child > a {border:0;}

/* MOBILE NAVIGATION WITH JS */

.js .main-nav {
    display: flex;
    flex-direction: column;
    //clear:both;
    max-height: 0;
    overflow: hidden;
    //transition: max-height 0.25s ease-out, height 0.25s ease-out;
    position: absolute;
    z-index: -1;
    top:0;
    width:100%;

}

.js .main-nav > ul {
    padding-bottom:8px;
    @media screen and (min-width: $break-tablet) {
        padding-bottom:0;
    }
}


.js .mob-nav-open .main-nav {
    max-height:none;
    overflow-y:auto;
    height: 100vh;
    padding-top:52px;
}
/*
.js .mob-nav-open .main-nav::before {
    content:'';
    background-color:$white;
    position: absolute;
    top:0;
    width:100%;
    height:100px;
}
*/

/* TABLET NAVIGATION */

@media screen and (min-width: $break-tablet) {

    .js .main-nav {
        display:block;
        width: auto;
        max-height:none;
        overflow:visible;
        position: relative;
        z-index: 1;
        top:auto;
    }

    .main-nav ul ul {
        padding:23px 0 10px 0;
        //
        position:absolute;
        left:0;
        top:100%;
        width:200px;
        //
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.075);
        // Hide visually
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
    }

    .main-nav > ul > li:hover > ul {
        height: auto;
        clip: auto;
        width:150px; 
    }

    .main-nav > ul {
        display:flex;
        align-items: center;
        padding-bottom:0;
    }

    .main-nav a {
        border-bottom:0;
        padding: 11px 8px 9px 8px;
    }

    .main-nav > ul > li > a {
        //padding: 30px 10px 30px 15px;
    }

    .main-nav > ul > li:hover > a,
    .main-nav a:hover,
    .main-nav a:focus,
    .main-nav a.active {
        //color: $white;
        //background-color: $primary;
    }

    .main-nav ul ul li > a {
        background-color: rgba(0,0,0,0.7);
    }


    .header-scrolled .main-nav ul ul li > a {
        background-color: $black;
    }

    .header-scrolled .main-nav ul ul {
        padding-top:10px;
    }
    

}

/* DESKTOP WIDE NAVIGATION */
@media screen and (min-width: $break-desktop-wide) {
    // .main-nav > ul > li > a {
    //     margin-right:6px;
    // }
}
