/*
------------------------------------------------------------------ CAROUSELS / BANNERS / SLIDERS

------------------------------------------------------------------------------------------------
*/

.carousel {font-size:1em; list-style-type:none; margin:0 0 32px 0; padding:0;}
.carousel li {margin:0; padding:0; display:none;}
.carousel>li:first-child {display:block;}

.carousel img {margin-bottom: 0; display: block;}

.slick-initialized .slick-slide {display:block;}

.slick-prev,
.slick-next {
    border-radius:0; margin-right:0; margin-left:0; z-index: 200; width:70px; height:98%;
}

.slick-prev {left: 0;}
.slick-next {right: 0; text-align:right;}

.slick-next:before,
.slick-prev:before {
    /*@include icon();*/ background-position:-314px -50px; width:24px; height:24px; position:absolute; margin-top:-12px; top:50%; left:23px; opacity:1;
}

.slick-prev:before {background-position:-286px -50px;}

.slick-next:hover:before {background-position:-314px 0;}
.slick-prev:hover:before {background-position:-286px 0;}

.slick-dotted.slick-slider {margin-bottom: 0;}

.slick-dots {bottom: 24px; margin:0; padding:0;}
.slick-dots li {display:inline-block; width:20px; height:28px; }

.slick-dots li button {width:20px; height:28px;}
.slick-dots li button:before {
    content: " ";
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #f1f1f1;
    top: 8px;
    left: 4px;
}

.slick-dots li button:before {opacity: 1;}
.slick-dots li.slick-active button:before {opacity: 1; background-color:#a5a09f;}


//
@media screen and (min-width: $break-tablet) {//  720/16

    .slick-prev,
    .slick-next {
        width:100px;
    }

    .slick-next:before,
    .slick-prev:before {
        background-position:-252px -50px; width:30px; height:30px; left:35px; margin-top:-15px;
    }

    .slick-prev:before {background-position:-218px -50px;}

    .slick-next:hover:before {background-position:-252px 0;}
    .slick-prev:hover:before {background-position:-218px 0;}

}

// CAROUSEL WITH BACKGROUND IMAGES
.carousel.carousel-bg li {position: relative; vertical-align:top;}
.carousel.carousel-bg li:before {display: block; content: ""; width: 100%; padding-top: 56.25%; }
.carousel.carousel-bg li div {position:absolute; top:0; right:0; bottom:0; left:0; background-position: 50% 50%; background-size: cover;}
