/*
-------------------------------------------------------------------------------- DATA TILES

------------------------------------------------------------------------------------------------
*/

 .data-tiles {

 }

 .data-tiles .help-block {
   margin-bottom: 10px;
 }

 .data-tiles.hide-data-tiles-list .data-tiles-list{
   display:none;
 }
 
 .data-tiles-list .data-tiles-item {
  background-color: $form-input-bg-alt;
  border:1px solid $form-input-border-alt;
  padding:20px;
  margin-bottom:20px;
  border-radius:3px;
  opacity:1;
  transition: opacity 0.2s linear;
  position: relative;
 }

 .modal-body .data-tiles-list .data-tiles-item {
  background-color: transparent;
  border-color: $bg-grey-paler;
 }

 .data-tiles-list .data-tiles-grid-item {
  opacity:1;
  transition: opacity 0.2s linear;
  max-width: 480px;
 }

 .data-tiles-list .data-tiles-item.fade-out,
 .data-tiles-list .data-tiles-grid-item.fade-out {
   opacity:0;
 }

 .data-tiles-list .data-tiles-item::after {
   content:'';
   position: absolute;
   top:0;
   width:100%;
   height:0;
   background-color: $bg-grey-paler;
   opacity:0;
   transition: opacity 0.2s linear;
 }

 .data-tiles-list .data-tiles-item.disabled::after {
   height:auto;
   bottom:0;
 }
 .data-tiles-list .data-tiles-item.disabled-fade::after {
   opacity:0.4;
}

.data-tiles-btns{
  display:flex;
}

.data-tiles-btns .btn {
  margin-bottom:0;
  margin-right:16px;
}

.data-tiles-item h5 + p {
  margin-top: -12px;
}

.data-tiles-item .file-icon-thumb {
  background-color:#fdfdfd;
}

@media screen and (min-width: $break-tablet) {//  720/16
  
  .data-tile-icon-grid {
    display:flex;
  }
  .data-tile-icon-grid .file-icon{
    order: 1;
    margin-right:10px;
    min-width:42px;
  }
  .data-tile-icon-grid .data-tile-content{
    order: 2;
  }

  .data-tiles-list.data-tiles-grid-fixed,
  .data-tiles-list.data-tiles-grid-4,
  .data-tiles-list.data-tiles-grid-3,
  .data-tiles-list.data-tiles-grid-2{
    display:flex;
    flex-wrap: wrap;
    margin-right:-30px;
  }

  
  .data-tiles-list.data-tiles-grid-fixed .data-tiles-grid-item {
    -ms-flex-preferred-size: 190px;
    flex-basis: 190px;
    max-width: 190px;
    margin-right:gutter($grid-tablet);
  }

  .data-tiles-list.data-tiles-grid-fixed.data-tiles-wide .data-tiles-grid-item {
    flex-basis: 450px;
    max-width: 450px;
  }

  .data-tiles-list.data-tiles-grid-fixed .form-thumbs-item {
    padding-right:0;
  }

  .data-tiles-list.data-tiles-grid-3 .data-tiles-grid-item {

    flex: 0 1 33.3333%;
    padding-right:30px;

    /*-ms-flex-preferred-size: span(4, $grid-tablet);
    flex-basis: span(4, $grid-tablet);
    max-width: span(4, $grid-tablet);
    margin-right:gutter($grid-tablet);*/
  }

  .data-tiles-list.data-tiles-grid-3 .data-tiles-item:nth-child(3n) {
    //margin-right:0;
  }

  .data-tiles-list.data-tiles-grid-4 .data-tiles-grid-item {

    flex: 0 1 25%;
    padding-right:30px;

    /*-ms-flex-preferred-size: span(4, $grid-tablet);
    flex-basis: span(4, $grid-tablet);
    max-width: span(4, $grid-tablet);
    margin-right:gutter($grid-tablet);*/
  }

  .data-tiles-list.data-tiles-grid-2 .data-tiles-grid-item {
    flex: 0 1 50%;
    padding-right:30px;
    max-width:none;
    /*-ms-flex-preferred-size: span(6, $grid-tablet);
    flex-basis: span(6, $grid-tablet);
    max-width: span(6, $grid-tablet);
    margin-right:gutter($grid-tablet);*/
  }

  .data-tiles-list.data-tiles-grid-2 .data-tiles-item:nth-child(2n) {
    //margin-right:0;
  }

}

/* DRAGGABLE BLOCKS */

.data-tiles-list:focus,
.data-tiles-grid-item:focus,
.data-tiles-item:focus {
  border:0;
  outline:0;
}

.data-tiles-list-sortable .data-tiles-item:hover {
  cursor:move;
}

.data-tiles-grid-item.draggable-source--is-dragging .data-tiles-item::before {
  background-color: red;
  content:'';
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  height:100%;
  width:100%;
  //
  z-index: 2;
  //
  border:2px dashed $placeholder-border;
  background-color: $placeholder-bg;
}

.data-tiles-grid-item.draggable-mirror{
  z-index: 3;
}

.data-tiles-grid-item.draggable-mirror .data-tiles-item{
  box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.1);
}