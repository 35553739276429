/*
------------------------------------------------------------------------------------------------
  _site_variables.scss - SASS VARIABLES AND MIXINS
------------------------------------------------------------------------------------------------
  Created:  01-03-2015
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/

/* Main Colours */

$white: #ffffff;
$black: #000000;

$primary: #e4ae2a; //ebb83c; // yellow
$primary-hover: #f4b316; // yellow
$primary-disabled: #f2cf63; // yellow

$secondary: #209cee; // Blue
$secondary-hover: #1397ee; // Blue
$secondary-disabled: #63b7ee; // Blue


// Button Colours
$btn-primary: #ebb83c; // Yellow
//$btn-primary-grad: linear-gradient(to right, #f4ba26 0%,$btn-primary 50%,#f4ba26 100%);
$btn-primary-border: #f7b919;


// Link Colours  ddac00
$link-primary: #dfad41; // Yellow
$link-primary-hover: #dfad41; // Yellow

// gold gradient
$gradient-fallback-gold:#f0b323;
$gradient-gold: linear-gradient(90deg,#dba83a 0%, #f6cd35 50%, #dba83a 100%);

// Text Colours
$text-dark: #191919;
$text-main: #292929;
$text-pale: #545454;
$text-paler: #cccccc;
$text-on-dark: #a8a8a8;
$text-placeholder: #8f8e8e;


// Background Colours
$bg-grey-palest: #fafbfd;
$bg-grey-paler: #f6f7f7;
$bg-grey-pale: #e5e5e5;
$bg-grey-mid: #696969;
$bg-grey-dark: #555555;
$bg-grey-darker: #434343;


// Buttons
$btn-default-border: #dcdcdc; // grey
$btn-default-bg: #eff0f1; // grey
$btn-default-hover-border: #a6a6a6; // darker grey
$btn-default-hover-bg: #eaebec; // darker grey


// Border Colours
$border-grey-paler: #ececec; //e5e5e5;
$border-grey-pale: #e0e0e0;
$border-grey-mid-pale: #cfcfcf;
$border-grey-mid: #8c8c8c;
$border-primary: #f0b323;


// Form colours
/*$form-input-border: #38424b;
$input-border: #cecfcf;
$form-input-bg: #38424b;
$form-input-bg-pale: #f7f7f7;
$form-input-bg-pale-hover: #eeeeee;
$form-input-focus-bg: #38424b;*/

$form-input-border: #cecfcf;
$input-border: #cecfcf;
$form-input-bg: #ffffff;
$form-input-bg-pale: #f7f7f7;
$form-input-bg-pale-hover: #eeeeee;
$form-input-focus-bg: #fafafc;

$form-input-border-alt: #49545f; //#293037;
$form-input-bg-alt: #38424b;
$form-input-bg-pale-alt:#282e34;
$form-input-focus-bg-alt: #46535e;


// UI Colours
$placeholder-bg: #f2fbff;
$placeholder-border: #b6bcbf;

$danger: #ed5565; // red
$btn-danger: #ed5565;// red
$btn-danger-hover: #ec4758;// red
$danger-pale: #ed5565; // red

$warning: $link-primary; // yellow
$success: #5ab54a; // green

$success: #78ac26; // green
$success-pale: #aed289; // green
$success-dark: #507f22;// green

$info: #3d7ebf; // blue
$info-dark: #225e9a; // blue

// Alert Colours

$alert-info-bg: #d9edf7;
$alert-info-bg-dark: #b5d5db;
$alert-info-content: #31708f;
$alert-info-link: #245269;


$alert-success-bg: #dff0d8;
$alert-success-bg-dark: #d6e9c6;
$alert-success-content: #3c763d;
$alert-success-link: #2b542c;


$alert-warning-bg: #fcf8e3;
$alert-warning-bg-dark: #f5e0b9;
$alert-warning-content: #a07426;
$alert-warning-link: #66512c;


$alert-danger-bg: #f2dede;
$alert-danger-bg-dark: #ebccd1;
$alert-danger-content: #a94442;
$alert-danger-link: #843534;

/* Z-indexes */
$z-modal: 2045;
$z-modal-wrap: 2043;
$z-modal-bg: 2042;
$z-dropdown: 2020;
$z-floating-alert: 2010;
$z-header: 2008;
$z-navigation: 2006;
$z-overlay: 2000;

/* Fonts */
$helvetica-thin: 'HelveticaNeueLTStd-Th';
$helvetica-light: 'HelveticaNeueLTStd-Lt';
$helvetica-medium: 'HelveticaNeueLTStd-Md';

$helvetica-thin-italic: 'HelveticaNeueLTStd-ThIt';
$helvetica-light-italic: 'HelveticaNeueLTStd-LtIt';
$helvetica-medium-italic: 'HelveticaNeueLTStd-MdIt';

$font-family-thin: $helvetica-thin, Arial, sans-serif;
$font-family-light: $helvetica-light, Arial, sans-serif;
$font-family-medium: $helvetica-medium, Arial, sans-serif;

$font-family-heading: 'HelveticaNeueLTStd-Th', Arial, sans-serif;
$font-family-heading-strong: 'HelveticaNeueLTStd-Lt', Arial, sans-serif;

$font-family-body: 'HelveticaNeueLTStd-Th', Arial, sans-serif;
$font-family-body-strong: 'HelveticaNeueLTStd-Lt', Arial, sans-serif;

//$font-family-body: 'HelveticaNeueLTStd-Th', Arial, sans-serif;
//$font-family-body-strong: 'HelveticaNeueLTStd-Lt', Arial, sans-serif;*/

// [CCS-17] - HELVETICA
//$font-family-body: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//$font-family-body-strong: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//$font-family-heading: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//$font-family-heading-strong: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";



$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;

/* Breakpoints */

$break-landscape: 30em; //  496px/16
$break-tablet: 45em; //  720px/16
$break-desktop: 58em; // 928px/16
$break-desktop-wide: 66em; // 1056px/16

/* Mixins */

@mixin appearance($app) {
    -webkit-appearance: $app;
    -moz-appearance: $app;
    -ms-appearance: $app;
    -o-appearance: $app;
    appearance: $app;
}

/* Reset all LIs that aren't in body content */
@mixin li-reset() {
    padding: 0; text-indent: 0;
    &:before{
        content: ""; display: none; background:none; width:auto; height:auto; padding:0;
    }
}


/* Override the bourbon mixin as ie8 doesn't support :: */
@mixin clrfix() {
    &:after {
        clear: both;
        content: "";
        display: table;
    }
}

/* Add icon to element */

@mixin icon() {
    content: '';
    display: inline-block;
    background: url('../img/ember-icon-sprite.png') 0 0 no-repeat;
    width: 20px;
    height: 20px;
    @media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
        background-image: url('../img/retina/ember-icon-sprite.png');
        background-size: 1000px 150px;
    }
}

/* Hide text within icon link */
@mixin icon-hide(){
    display:block;
    overflow:hidden;
    word-wrap: normal; /* WORD WRAP IS IMPORTANT!! */
    white-space:nowrap;
    text-indent:101%;
}


/* Switch Font smoothing settings */
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

/* Screen reader only text */
@mixin sr-only() {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    border: 0!important;
    white-space: nowrap!important;
}



/* Calculate REM based on pixel input */
@function calculateRem($size) {
    $remSize: calc($size / 16px);
    @return #{$remSize}rem;
}
@mixin fontSize($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}

// Strip a unit from a value (for calculating unitless line-heights)
@function strip-unit( $number ) {
	@if type-of( $number ) == 'number' and not unitless( $number ) {
		@return $number / ( $number * 0 + 1 );
	}
	@return $number;
}

// Calculate percentage ratio
@function calcRatio($width, $height) {
    @return calc($height / $width)*100%;
}
