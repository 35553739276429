/*
------------------------------------------------------------------------------ TABBED NAVIGATION

------------------------------------------------------------------------------------------------
*/

.nav-tabs {
    //@include fontSize(14px);
    list-style-type: none;
    padding: 0;
    margin: 0;
    //margin: 0 0 20px 0;
    //
    line-height: 1.2;  
    margin-left: 0;
  
    position: relative;
}

.nav-tabs a {
    display:block;
    padding:6px 0;
}

@media screen and (min-width: $break-tablet) {//  720/16
    // these will be shown as mobile nav's on mobile

    .nav-tabs {
        padding-left:10px;
        //margin-left: -8px;
        //
        display:flex;

        @media screen and (min-width: $break-desktop) {//  720/16
            //@include fontSize(15px);
        }
    }

    .nav-tabs:before {
        content:'';
        width:100%;
        height:1px;
        position: absolute;
        bottom:0;
        left:0;
        background-color: $primary;
        //
        z-index: 1;
    }

    .nav-tabs li {
        list-style-type:none;

        padding:0;
        margin: 0 6px 0 0;

        flex: 0 1 auto;

        display:flex;
        align-items:center;
        flex-direction: column;
        text-align: center;


    }

    .nav-tabs a {

        font-weight: $font-light;
        text-decoration: none;
        padding: 8px;

        color:$white;
        background-color: $black;
        border: 1px solid #6e6e6e;
        border-bottom: 0;

        transition: background-color 0.05s linear, color 0.05s linear, opacity 0.05s linear;

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        line-height: 18px;
        width:100%;

        flex: 1 1 auto;

        align-content: center;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: $break-desktop) {//  720/16
            padding:12px 24px;
        }

    }

    .nav-tabs a:hover,
    .nav-tabs a.active {
        //background-color: $white;
        //color:$text-dark;
        border-color: $primary;
    }

    .nav-tabs a.active {
        position: relative;
        z-index: 2;
    }

    .nav-tabs a:focus,
    .nav-tabs a:hover:focus,
    .nav-tabs a.active:focus {
        text-decoration: underline;
    }

    $exclamation-circle-warning: svg-exclamation-circle($warning);
    $exclamation-circle-danger: svg-exclamation-circle($danger);

    .nav-tabs a.nav-tab-warning,
    .nav-tabs a.nav-tab-danger {
        position: relative;
        padding-left:48px;
    }


    .nav-tabs a.nav-tab-warning::before,
    .nav-tabs a.nav-tab-danger::before {
        content: '';
        width:22px;
        height:22px;
        position: absolute;
        top:50%;
        left:18px;
        margin-top:-11px;
    }

    .nav-tabs a.nav-tab-warning::before{
        background:url(svg-encode($exclamation-circle-warning)) 0 0 no-repeat;
    }

    .nav-tabs a.nav-tab-danger::before{
        background:url(svg-encode($exclamation-circle-danger)) 0 0 no-repeat;
    }



    /* TABBED CONTENT */

    .tab-pane {
        border:1px solid $primary;
        border-top:0;

        padding:30px 20px;

        @media screen and (min-width: $break-desktop) {//  720/16
            padding-left:30px;
            padding-right:30px;
        }
        
        /*background-color: $white;
        

        @media screen and (min-width: $break-desktop) {//  720/16
            padding-left:30px;
            padding-right:30px;
        }
        border-radius: 5px;*/
    }

}

@media screen and (min-width: $break-desktop) {//  496px/16

    .tab-grid-content {
        -ms-flex-preferred-size: span(6, $grid-tablet);
        flex-basis: span(6, $grid-tablet);
        max-width: span(6, $grid-tablet);
    }

    .tab-grid-aside {
        -ms-flex-preferred-size: span(5, $grid-tablet);
        flex-basis: span(5, $grid-tablet);
        max-width: span(5, $grid-tablet);
    }

}


//.tab-content .tab-pane {display: none;}
//.tab-content .tab-pane.active {display: block;}

/* ANIMATION */
/*.js .tab-content .tab-pane {height:0; overflow:hidden; opacity: 0; transition: opacity 0.3s linear; }
.js .tab-content .active {height:auto; overflow:visible;}
.js .tab-content .tab-pane.fade-in {opacity: 1;}
.js .tab-content .tab-pane.hide {display: none;}*/
