/*
------------------------------------------------------------------------------------- AJAX UPDATE

------------------------------------------------------------------------------------------------
*/

.field-with-update {
    padding-right:34px;
    position: relative;
}

@keyframes spinnerAjaxAnim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.field-with-update::after {
    content:'';
    position: absolute;
    top:9px;
    right:0;
    border-radius: 50%;
    //
    width:20px;
    height:20px;
    //
    border-top: 3px solid rgba(255, 255, 255, 0.3);
    border-right: 3px solid rgba(255, 255, 255, 0.3);
    border-bottom: 3px solid rgba(255, 255, 255, 0.3);
    border-left: 3px solid $white;
    //
    transform: translateZ(0);
    animation: spinnerAjaxAnim 0.5s infinite linear;
    //
    display:none;
    opacity:0;
}

.checkbox .field-with-update::after,
.radio .field-with-update::after {
    top:0;
}

.toggle-with-labels .field-with-update::after {
    top:2px;
}

.ajax-updating .field-with-update::after {
    display:block;
    transition: opacity 0.15s linear;
}

.ajax-updating-animate .field-with-update::after {
    opacity:1;
}

.modal-body .field-with-update::after {
    border-top-color: rgba(50, 50, 50, 0.2);
    border-right-color: rgba(50, 50, 50, 0.2);
    border-bottom-color: rgba(50, 50, 50, 0.2);
    border-left-color: $text-main;
}




$bg-tick-circle-success: svg-tick-circle($success);

.ajax-updating-success .field-with-update::after {
    border:none;
    animation: none;
    background: url(svg-encode($bg-tick-circle-success)) 0 0 no-repeat;
}

$bg-cross-circle-danger: svg-cross-circle($danger);

.ajax-updating-failure .field-with-update::after {
    border:none;
    animation: none;
    background: url(svg-encode($bg-cross-circle-danger)) 0 0 no-repeat;
}