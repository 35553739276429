/*
 *
 *   Form Thumbs
 *   Version: 1.0.2 - [CCS-198] - multiple uploads
 *
 */


.form-thumbs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.form-thumbs-item {
    flex: 1 1 200px;
    max-width: 200px;
    //
    display: block;
    position: relative;
    padding: 0 32px 0 0;
    margin-bottom: 20px;
    //
    transition: opacity 0.3s linear;
    opacity:0;
}

.form-thumbs-item.fade-in {
    opacity:1;
}

.form-thumb-img {
    border: 1px solid $form-input-border;
    background-color: $bg-grey-paler;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
}

/* FILE ICONS */

.file-icon-thumb {
    position: relative;
    border: 1px solid $form-input-border;
    background-color: $bg-grey-paler;
    margin-bottom: 4px;
    min-height: 90px;
}

.file-icon-thumb-img {
    width: 42px;
    height: 42px;
    //
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -21px;
}

.form-thumbs-filename-text {
    @include fontSize(12px);
}

/* MATCH FILE ICON TO AN IMAGE RATIO */

.file-icon-thumb.file-icon-thumb-ratio {
    min-height: 0;
    position: relative;
    vertical-align: top;
    overflow: hidden;
}

.file-icon-thumb-ratio-spacer {
    width: 100%;
}

.file-icon-thumb.file-icon-thumb-ratio .form-thumb-img{
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
}

/* DELETE BUTTON */

.form-thumbs-item .del {
    display: none;
    padding: 0 0 0 5px;
    width: 19px;
    height: 19px;
    position: absolute;
    top: 0;
    right: 10px;
    border-radius: 3px;
    transition: background 0.15s linear, color 0.15s linear;
    z-index: 5;
    @include icon-hide;
}

.form-thumbs-show-del .form-thumbs-item .del {
    display: block;
}

$bg-close-cross: svg-close-cross($text-main);

.form-thumbs-item .del::before {
    content:'';
    display: block;
    width:9px;
    height:9px;
    position: absolute;
    top:5px;
    left:5px;
    background: url(svg-encode($bg-close-cross)) 0 0 no-repeat;
}

.form-thumbs-item .del:hover {
    background-color: $danger;
}

$bg-close-cross-white: svg-close-cross($white);

.form-thumbs-item .del:hover::before {
    background: url(svg-encode($bg-close-cross-white)) 0 0 no-repeat;
}

.form-thumbs-item .del.disabled,
.form-thumbs-item .del.disabled:hover {
    opacity: 0.6;
    background-color: transparent;
    cursor: default;
}

.form-thumbs-item .del.disabled::before,
.form-thumbs-item .del.disabled:hover::before {
    background: url(svg-encode($bg-close-cross)) 0 0 no-repeat;
}
