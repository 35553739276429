/*
-----------------------------------------------------------------------0---------- CONTROL BLOCK

------------------------------------------------------------------------------------------------
*/

/* BLOCK TITLE WITH CONTROLS (EG SHOW /HIDE) */

.control-block {display: flex;}
.control-block-controls {margin-left: auto;}

.control-block-title h3 {
    margin-bottom: 0;
}

.control-block-arrow {
    @include icon-hide;
    width:40px;
    height:40px;
    position: relative;
}

.control-block-arrow::before {
    content:'';
    display: block;
    width:10px;
    height: 10px;
    //
    position: absolute;
    top:12px;
    left:16px;
    //
    transform: translateY(0) rotate(45deg);
    border: solid $text-main;
    border-width: 0 2px 2px 0;
}

.truncated-block.animating .control-block-arrow::before {
    transform: translateY(5px) rotate(225deg);
}
