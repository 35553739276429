/*
-------------------------------------------------------------------------------- AJAX MODAL FORM

------------------------------------------------------------------------------------------------
*/

.modal-results-inner {
    height:auto;
}

.modal-results-inner.animating {
    transition: max-height 0.4s ease-in-out;
    overflow: hidden;
}

.modal-results-inner.animating-fast {
    transition: max-height 0.3s ease-in-out;
}

.modal-results-inner.animating-slow {
    transition: max-height 0.8s ease-in-out;
}
