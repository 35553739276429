/*
----------------------------------------------------------------------------------- SITE OVERLAY

------------------------------------------------------------------------------------------------
*/

.overlay {
    position: fixed;
    width:100%;
    top: 0;
    right: 0;
    left: 0;
    height:0;
    //
    transition: opacity .3s ease-in-out;
    //
    opacity:0;
    z-index: $z-overlay;
}

.overlay.overlay-visible {
    bottom: 0;
    height:auto;
}

.overlay.overlay-animating {
    opacity: 1;
}


.overlay:before {
    content:'';
    display: block;
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color: $black;
    opacity: 0.8;
}
