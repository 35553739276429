// Bootstrap Datepicker overrides


/* Datepicker3.css date and date-range override */

.input-group.date .input-group-addon {cursor: pointer;}
.input-group.date .input-group-addon i {width: 16px; height: 16px;}
.input-group.date .form-control {text-align:left;}

.input-group.date .input-group-addon:first-child {
    background-color:$form-input-bg-alt; 
    padding:6px 12px 6px 11px; 
    margin:0; 
    border-left: 1px solid $form-input-border-alt; 
}

.modal-body .input-group.date .input-group-addon:first-child {
    background-color:$white; 
    border-color:$input-border; 
}

.datepicker-dropdown.datepicker-orient-left:before {left: 14px;}
.datepicker-dropdown.datepicker-orient-left:after {left:15px;}

// Offest position a bit
.datepicker-orient-bottom {margin-top:-1px;}
.datepicker-orient-top {margin-top:1px;}

// Boost dropshadow a bit
.datepicker.dropdown-menu {

  border: 1px solid #cecfcf;

  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  //-webkit-background-clip: padding-box;
  //-moz-background-clip: padding;
  background-clip: padding-box;

}


// Datepicker Range icon width
.input-daterange .input-group-addon {width: 1%;}
.input-daterange .range-mid {padding-left:10px; padding-right:10px;}



// Make active day green
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
}

// Make active day hover dark green
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active.focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active:active:hover

.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active.disabled.active.focus,
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.disabled:hover.active.focus,
.datepicker table tr td span.active.disabled:hover.active:focus,
.datepicker table tr td span.active.disabled:hover.active:hover,
.datepicker table tr td span.active.disabled:hover:active.focus,
.datepicker table tr td span.active.disabled:hover:active:focus,
.datepicker table tr td span.active.disabled:hover:active:hover,
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active:hover.active.focus,
.datepicker table tr td span.active:hover.active:focus,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td span.active:hover:active.focus,
.datepicker table tr td span.active:hover:active:focus,
.datepicker table tr td span.active:hover:active:hover {
    color: #fff;
    background-color: darken($primary, 3%);
    border-color: darken($primary, 3%);
}


// disabled days
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {color:#bcbcbc;}

// Highlight days
.datepicker table tr td.highlight{background: #eee;}

//
.datepicker table tr td.today {
    background-color: #d1eff9;
    border-color: #d1eff9;
}
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #d1eff9;
    border-color: #d1eff9;
    color: #777777;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover {
    background-color: #eeeeee;
    border-color: #eeeeee;
}
