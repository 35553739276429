/*
--------------------------------------------------------------------------- SOCIAL LINKS / ICONS

------------------------------------------------------------------------------------------------
*/

.social-nav {list-style-type: none; padding:0; margin:0; @include clrfix;}
.social-nav li {float:left;}
.social-nav a {color:$primary; transition: color 0.2s ease-out;}
.social-nav a:hover {color:$primary;}

.icon-social {
    display: block;
    height:40px;
    padding:10px;
    padding-bottom: 0;
}

.icon-social .svg-icon {
    width:100%;
    height:100%;
}

/* ICON SIZING */
// Ratio percentages are based on the source SVG sizes
.icon-social.social-fb .icon-ratio {width:10px; min-width: auto;}
$fb-ratio: calcRatio(21,40);
.icon-social.social-fb .icon-ratio:before {padding-top:$fb-ratio;}

.icon-social.social-tw .icon-ratio {width:15px;}
$tw-ratio: calcRatio(40,32);
.icon-social.social-tw .icon-ratio:before {padding-top:$tw-ratio;}

.icon-social.social-li .icon-ratio {width:16px;}
$li-ratio: calcRatio(40,40);
.icon-social.social-li .icon-ratio:before {padding-top:$li-ratio;}

.icon-social.social-pi .icon-ratio {width:12px;}
$pi-ratio: calcRatio(30,40);
.icon-social.social-pi .icon-ratio:before {padding-top:$pi-ratio;}

.icon-social.social-in .icon-ratio {width:15px;}
$in-ratio: calcRatio(40,40);
.icon-social.social-in .icon-ratio:before {padding-top:$in-ratio;}

.icon-social.social-yt .icon-ratio {width:20px;}
$yt-ratio: calcRatio(40,27);
.icon-social.social-yt .icon-ratio:before {padding-top:$yt-ratio;}

.icon-social.social-gplus .icon-ratio {width:20px;}
$gplus-ratio: calcRatio(40,26);
.icon-social.social-gplus .icon-ratio:before {padding-top:$gplus-ratio;}

.icon-social.social-email .icon-ratio {width:15px;}
.icon-social.social-email .icon-ratio:before {padding-top:75%;}

/* ICON POSITIONING */
.icon-social.social-tw {padding-top: 12px;}
.icon-social.social-in {padding-top: 11px;}
.icon-social.social-yt {padding-top: 13px;}
.icon-social.social-gplus {padding-top: 14px;}
.icon-social.social-email {padding-top: 13px; padding-left: 8px; padding-right: 8px;}


/* ICONS IN A CIRCLE */
.icon-social-circle li {margin-right: 10px;}
.icon-social-circle .icon-social {border-radius: 50%; width:40px; background-color: $bg-grey-mid; color:$white; padding-right:0;}

.icon-social-circle .icon-social.social-fb {padding-left:13px;}
.icon-social-circle .icon-social.social-tw {padding-left:11px; padding-top:13px;}
.icon-social-circle .icon-social.social-li {padding-top:9px;}
.icon-social-circle .icon-social.social-pi {padding-left:12px; }
.icon-social-circle .icon-social.social-in {padding-top:10px; }
.icon-social-circle .icon-social.social-yt {padding-top:13px; padding-left:9px; }
.icon-social-circle .icon-social.social-gplus {padding-top:13px; }

/* ICONS IN A SQUARE */
.icon-social-square li {margin-right: 10px;}
.icon-social-square .icon-social {border-radius: 6px; width:40px; background-color: $bg-grey-mid; color:$white; padding-right:0;}

.icon-social-square .icon-social.social-fb {padding-left:13px;}
.icon-social-square .icon-social.social-tw {padding-left:11px; padding-top:13px;}
.icon-social-square .icon-social.social-li {padding-top:9px;}
.icon-social-square .icon-social.social-pi {padding-left:12px; }
.icon-social-square .icon-social.social-in {padding-top:10px; }
.icon-social-square .icon-social.social-yt {padding-top:13px; padding-left:9px; }
.icon-social-square .icon-social.social-gplus {padding-top:13px; }



/* TEXT NEXT TO ICONS */
.icon-social-link span {display: none;}
.icon-social-text .icon-social-link {@include clrfix;}
.icon-social-text .icon-social-link .icon-social {float:left;}
.icon-social-text .icon-social-link span {display: block; float:left; padding-left: 6px; padding-top:2px; @include fontSize(14px);}

/* LARGE ICONS */

.icon-social-circle.icon-social-lrg .icon-social-link {padding:0 6px;}
.icon-social-circle.icon-social-lrg .icon-social {height:40px; width: 40px;}

.icon-social-circle.icon-social-lrg .icon-social {padding-top: 10px; padding-left: 10px;}

.icon-social-circle.icon-social-lrg .social-nav-text {padding-top:10px;}

.icon-social-circle.icon-social-lrg .icon-social.social-fb .icon-ratio {width:12px;}
.icon-social-circle.icon-social-lrg .icon-social.social-tw .icon-ratio {width:20px;}
.icon-social-circle.icon-social-lrg .icon-social.social-li .icon-ratio {width:20px;}
.icon-social-circle.icon-social-lrg .icon-social.social-pi .icon-ratio {width:16px;}
.icon-social-circle.icon-social-lrg .icon-social.social-in .icon-ratio {width:20px;}
.icon-social-circle.icon-social-lrg .icon-social.social-yt .icon-ratio {width:22px;}
.icon-social-circle.icon-social-lrg .icon-social.social-gplus .icon-ratio {width:22px;}

.icon-social-circle.icon-social-lrg .icon-social.social-fb {padding-left:14px;}
.icon-social-circle.icon-social-lrg .icon-social.social-tw {padding-left:11px; padding-top:13px;}
.icon-social-circle.icon-social-lrg .icon-social.social-li {padding-top:9px;}
.icon-social-circle.icon-social-lrg .icon-social.social-pi {padding-left:12px; }
.icon-social-circle.icon-social-lrg .icon-social.social-in {padding-top:10px; }
.icon-social-circle.icon-social-lrg .icon-social.social-yt {padding-top:13px; padding-left:9px; }
.icon-social-circle.icon-social-lrg .icon-social.social-gplus {padding-top:13px; }
