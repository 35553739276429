/*
---------------------------------------------------------------------- ACCOUNT PROGRESS TIMELINE

------------------------------------------------------------------------------------------------
*/

.progress-timeline {
   position:relative;
   list-style:none;
   margin:0 0 1rem 0;
   padding:0;
   width:100%;
   //
   display:flex;
}

.progress-timeline::before {
   content:'';
   display: block;
   width:100%;
   height:2px;
   background:$form-input-border-alt;
   //
   position: absolute;
   top:16px;
   left:0;
}

.progress-timeline li {
   flex: 1;
   text-align: center;
   position:relative;
   padding: 0;
}

.progress-timeline li::before {
   content:'';
   width: 32px;
   height: 32px;
   border-radius: 50%;
   background: $form-input-bg-alt;
   border:3px solid $form-input-border-alt;
   //
   position: absolute;
   top:0;
   left:50%;
   margin-left:-16px;
   //
   pointer-events: none;
}

.progress-timeline a {
   color: $white;
   font-weight: $font-light;
   display:block;
   padding-top: 40px;
   cursor: default;
}

/* STATES */

$thick-tick-white: svg-thick-tick($white);
$thick-exclamation-white: svg-thick-exclamation($white);

// Active
.progress-timeline li.active a {
   font-weight: $font-medium;
}

.progress-timeline li.active::before {
   border-color:$primary;
   width:38px;
   height:38px;
   margin-left: -19px;
   margin-top:-3px;
}

// Complete

.progress-timeline li.complete a {
   cursor: pointer;
}

.progress-timeline li.complete::before {
   //background: $bg-grey-pale;
   background: $form-input-bg-alt;
}

.progress-timeline li.complete::after {
   content:'';
   width:15px;
   height:11px;
   background: url(svg-encode($thick-tick-white)) 0 0 no-repeat;
   //
   position: absolute;
   top:11px;
   left:50%;
   transform: translateX(-50%);
   // 
   pointer-events: none;
}

.progress-timeline li.active.complete::after {
   width:19px;
   height:14px;
   background-size: 19px 14px;
   top:10px;
}

// inComplete
.progress-timeline li.incomplete a {
   cursor: pointer;
}

.progress-timeline li.incomplete::before {
   background: $bg-grey-pale;
}

.progress-timeline li.incomplete::after {
   content:'';
   width:5px;
   height:21px;
   background: url(svg-encode($thick-exclamation-white)) 0 0 no-repeat;
   //
   position: absolute;
   top:6px;
   left:50%;
   transform: translateX(-50%);
   // 
   pointer-events: none;
}

// Active and Complete
.progress-timeline li.active.complete::before,
.progress-timeline li.active.incomplete::before {
   background: $primary;
}