/*
---------------------------------------------------------------------------- ICON SPECIFIC STUFF

------------------------------------------------------------------------------------------------
*/

.icon-ratio.icon-ratio-down-arrow-sm {
    width: 10px;
    min-width: 10px;
}


$exclamation-circle-outline-white: svg-exclamation-circle-outline($white);

.icon-exclamation-circle-outline-white {
    width:22px;
    height:22px;
    background: url(svg-encode($exclamation-circle-outline-white)) 0 0 no-repeat;
}