/*
------------------------------------------------------------------------------------------------
  _site_forms.scss - FORM ELEMENTS BASE STYLES
------------------------------------------------------------------------------------------------
  Created:  01-03-2016
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/

input, textarea, select, label {
    font-family: inherit;
}

/* Labels */


label.control-label {
    color: $primary;
    @include fontSize(16px);
    line-height: 1.5;
    padding-top: 4px;
    margin-bottom:2px;
    display: inline-block;
    max-width: 100%;
    width: 100%;
    font-weight: $font-regular;
    //@include font-smoothing(on);
    text-align: left;
}

.modal-body label.control-label {
    color: $text-main;
}

/* Fields */

input, textarea, button, .btn{
    box-sizing: border-box;
    outline: none;
}

.form-control {
    color: $white;

    @include fontSize(16px);
    line-height: 1.5;

    padding: 6px 14px;
    margin: 0;

    border: 1px solid $form-input-border-alt;
    background-color: $form-input-bg-alt;

    width: 100%;
    height: 38px;

    outline: none;

    transition: border-color 0.3s ease-out, background 0.3s ease-out;
    border-radius:0;

    vertical-align: top;

    @include font-smoothing(on);
    @include appearance(none);

    &:focus {
        border-color:$primary;
        background-color:$form-input-focus-bg-alt;
    }
}

.modal-body .form-control {
    border-color: $form-input-border;
    background-color: $form-input-bg;
    color: $text-dark;

    &:focus {
        border-color:$primary;
        background-color:$form-input-focus-bg;
    }
}


textarea,
textarea.form-control{
    min-height:144px;
    margin:0;
    vertical-align: top;
    padding-top:8px;
    resize: vertical;
}


input[type="submit"] {border:none;}

input[type="radio"] {margin-right:10px;}

input[type="checkbox"], input[type="radio"] {
  padding:0 0 0 0;
  margin:-2px 6px 0 0;
  height: 16px;
  cursor: pointer;
}


.form-control-static {
    @include fontSize(14px);
    line-height: 1.5;
    min-height: 36px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0;
}

/* Input Groups */

.input-group {position: relative; display: table; border-collapse: separate; }

.input-group .form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.input-group .form-control:not(:first-child) {border-top-left-radius: 0; border-bottom-left-radius: 0;}
.input-group .form-control:not(:last-child) {border-top-right-radius: 0; border-bottom-right-radius: 0;}

.input-group .input-group-addon:first-child,
.input-group .input-group-btn:first-child > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .input-group-addon:last-child,
.input-group .input-group-btn:last-child > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
    display: table-cell;
}

.input-group .form-control,
.form-horizontal .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.input-group-addon,
.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    border-radius:2px;
}

.input-group.input-group-rounded .input-group-btn .btn {
    border-top-right-radius: 60px!important;
    border-bottom-right-radius: 60px!important;
}

.input-group-addon {
    padding: 7px 12px 5px 12px;
    @include fontSize(14px);
    line-height: 1;
    color: #767b81;
    text-align: center;
    //background-color: $form-input-bg-pale;
    border: 1px solid $form-input-border-alt;
    border-right:none;
    @include font-smoothing(on);
    text-decoration:none;
}

.modal-body .input-group-addon {
    color: $text-main;
    border-color: $form-input-border;
}



//.input-group-addon:hover {background-color: $form-input-bg-pale-hover;}

.input-group .form-control + .input-group-addon {border-left:none;}

.input-group .input-group-addon:last-child {
  border-right: 1px solid $form-input-border;
}

.input-group .input-group-addon:first-child {
    border-right: 0;
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
}

.input-group-btn>.btn {margin:0 -1px 0 0;}

.input-group-btn:last-child>.btn, .input-group-btn:last-child>button {
    //margin-left: -1px;
    margin-right:0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


/* misc */


input.table-input {margin:0; max-width: 80px; text-align: right;}
input.input-4-digits {max-width:80px;}

.form-group-sm label.control-label {padding-top: 3px; @include fontSize(14px);}
.form-group-sm .form-control {height: 30px; padding: 5px 10px; @include fontSize(14px); line-height: 1.5;}

.dummy-input, .input-group .dummy-input {/*position: relative; z-index:-1;*/ pointer-events: none;}

.help-block {
    @include fontSize(13px);
    display: block;
    margin-top: 5px;
    margin-bottom: 0;
    color: $white;
    text-align:left;
}

.modal-body .help-block {
    color: $text-pale;
}

@media screen and (min-width: $break-tablet) {
    .form-group-thin {
        max-width:240px;
    }
}


/* Small Inputs */

.input-sm {height:37px; padding-top:1px; padding-bottom:0;}

/* large Inputs */

.input-lg {height:44px; padding-top:9px; padding-bottom:9px;}


/* Disabled */

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: $form-input-bg-pale-alt;
    opacity: 0.8;
}


input[readonly]:focus{
  border-color:$form-input-border-alt;
  background-color:$form-input-bg-pale-alt;
}


.modal-body .form-control[disabled],
.modal-body .form-control[readonly],
.modal-body fieldset[disabled] .form-control {
    background-color: $form-input-bg-pale;
    opacity: 0.8;
}


.modal-body input[readonly]:focus{
  border-color:$form-input-border;
  background-color:$form-input-bg-pale;
}



/* Placeholder */

::-webkit-input-placeholder { /* Chrome */
  color: $text-placeholder;;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $text-placeholder;;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $text-placeholder;;
  opacity: 1;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: $text-placeholder;;
  opacity: 1;
}


/* Checkboxes and Selects */

.checkbox label, .radio label {
    min-height: 20px;
    margin-bottom: 0;
    padding-top:0;
    font-weight: 400;
    cursor: pointer;
    float:none;
    text-align:left;
}

.checkbox-inline,
.radio-inline{
    position: relative;
    display: inline-block;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
    margin-right:10px;
}

select.form-control {
  @include appearance(menulist);
  &:focus {
      //background-color:$form-input-bg;
  }
}



select[multiple], select[size] {
    height: auto;
    @include appearance(none);
}

/* Layout */

.form-group {clear:both; margin-bottom: 12px; @include clrfix;}
.form-block .hidden {display:none;}

.form-group.form-submit {padding-top:20px; margin-bottom:0;}
.form-group.form-submit .btn:last-child {margin-right:0;}


@media screen and (min-width: $break-tablet) {//  720/16

    .form-horizontal label.control-label {width: 25%; float:left; padding-top: 6px; margin-bottom: 0; padding-right: 16px; text-align:right;}
    .form-horizontal .form-col {width: 75%; float:left;}
    //.form-horizontal .form-control {width: 75%;}
    //.form-horizontal select.form-control {width: 100%;}
    //.form-horizontal .select {width: 75%; float:right;}

    /*.form-horizontal .error-msg,
    .form-horizontal .label-offset,
    .form-horizontal label.error {
        padding-left: 25%;
        padding-right: 8px;
        width: 100%;
        text-align:left;
    }

    .form-horizontal .check + label.error,
    .form-horizontal .radio + label.error {
        padding-left: 0;
    }

    .form-horizontal.no-labels label.error {padding-left: 0;}
*/

    .form-horizontal .checkbox,
    .form-horizontal .radio {
        min-height: 27px;
        padding-top: 12px;
        margin-top: 0;
        margin-bottom: 0;
      }

    .form-horizontal .checkbox label,
    .form-horizontal .radio label {
        float:none; width:auto; padding-top:0;
    }

    .form-horizontal .checkbox-group .checkbox,
    .form-horizontal .radio-group .radio {
        width:auto; margin-left:0;
    }

    .form-horizontal .form-submit {padding-left:25%;}

    .form-horizontal .rating,
    .form-horizontal .rating-static {
        padding-top:7px;
    }

}



.form-group-split {
    clear:both;
    display:flex;
}

.form-group-split .form-group {
    flex: 1;
    margin-right: 32px;
}

.form-group-split .form-group:last-of-type {
    margin-right: 0;
}


/* Inline Form */
@media screen and (min-width: $break-tablet) {//  720/16
    .form-inline .form-group {display: inline-block; margin-right:20px;}
    .form-inline .form-control {display: inline-block; width: auto; vertical-align: middle;}
    .form-inline .btn {margin-bottom:0;}
}


/* Validation */

.form-group .required .control-label:after {content:' *'; @include fontSize(11px); vertical-align:top;}

.error label.error {
    color:$danger;
    font-weight:$font-bold;
    text-align: left;
    display: inline-block;
    float:none;
    width:100%;
    @include fontSize(14px);
}

.error .form-control {border-color:$danger; border-width:2px;}
//.valid .form-control {border-color:$success; border-width:2px;}
.error label.control-label {color:$danger;}

/*.note {@include fontSize(13px);}
.small-label {@include fontSize(13px);}*/


.form-container {@include clrfix;}
.form-container-result {display:none;}


/* Floating Labels */
.float-labels .form-group {position:relative; padding-top:8px;}

.float-labels .form-group label.float-label {
  @include fontSize(11px);
  line-height:1;
  //font-weight:$font-semi-bold;
  position:absolute;
  top:-7px;
  left:0;
  //color:$text-pale;
  opacity:0;
  transition: all 0.1s linear;
}

.float-labels .form-group label.float-label.on {
  color:$primary;
}
.float-labels .form-group label.float-label.show {
  top:-12px;
  opacity:1;
}

/* Form Containers */

.form-block {
    background-color: transparent;
}
.breakout-form .form-block {
    background-color: transparent;
}

/* DOB Row */

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.form-group-dob-block {
    display: flex;
    //justify-content: center;
}

.form-group-dob-block .form-group-dob-month,
.form-group-dob-block .form-group-dob-day,
.form-group-dob-block .form-group-dob-year {
    //display: inline-block;
    margin-right: 10px;
}

.form-group-dob-block .form-group-dob-month {max-width:150px;}
.form-group-dob-block .form-group-dob-day {min-width: 64px; max-width:90px;}
.form-group-dob-block .form-group-dob-year {max-width: 90px;}

/* PHONE CODE ROW */

.form-group-phone-block {
    display: flex;
}

.form-group-phone-block .form-group-phone-code,
.form-group-phone-block .form-group-phone-num {
    margin-right: 10px;
}

.form-group-phone-block .form-group-phone-code {max-width:150px;}
.form-group-phone-block .form-group-phone-num {flex-grow: 1; min-width: 200px;}

/* FORM BLOCKS */

.form-block {
    /*background-color: $bg-grey-paler; 
    border-radius: 4px; 
    padding: 20px; */
    @include clrfix;
    margin-bottom: 20px;
}
