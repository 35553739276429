/*
--------------------------------------------------------------------- MAGNIFIC POPUP BASE STYLES

------------------------------------------------------------------------------------------------
*/

.mfp-modal {
    position: relative;
    background: #FFF;
    padding: 0;
    //width: auto;
    width: 100%; // for IE11
    max-width: 600px;
    margin: 60px auto 30px auto;
    @media screen and (min-width: $break-tablet) {
        margin-top: 80px;
    }
    @media screen and (min-width: $break-desktop) {
        margin-top: 100px;
    }
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow:0 1px 3px rgba(0,0,0,0.3);
    outline: 0 none;
    //overflow: hidden; // Force body bg to obey rounded corner (disabled as was clipping form dropdowns within - need another solution)
}

.mfp-close,
.mfp-close:active {
    margin:0;
    top:5px;
    width:38px;
    @media screen and (min-width: $break-tablet) {
        top:8px;
        width:44px;
    }
}

.mfp-bg {z-index: $z-modal-bg;}
.mfp-wrap {z-index: $z-modal-wrap;}
.mfp-content {z-index: $z-modal;}

.mfp-modal.mfp-modal-no-footer .modal-body {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}


/*
------------------------------------------------------------- REPLICATE BOOTSTRAP MODAL STYLINGS

------------------------------------------------------------------------------------------------
*/


//.mfp-content {vertical-align: top;} // Remove default vertical centering

.mfp-container { padding-left: 15px; padding-right: 15px;}

.mfp-close {font-size: 22px;} // Tone down close button a bit


.mfp-content .modal-header {padding: 15px; text-align: center;}

.mfp-content .modal-title {
    color:$text-main;
    @include fontSize(28px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(32px);
    }
    padding: 0 20px;
}
.mfp-content .modal-body {background: $bg-grey-palest;}

.modal-body h1, .modal-body h2, .modal-body h3, .modal-body h4 {
    color:$text-main;
}

//.modal-body {background-clip}

/*
--------------------------------------------------------------- REPLICATE BOOTBOX MODAL STYLINGS

------------------------------------------------------------------------------------------------
*/

.dialog-modal .modal-body {padding-bottom: 20px}
.dialog-modal .modal-body p {font-weight: $font-medium;}
.dialog-modal .modal-body p:last-child {margin-bottom: 0;}

.dialog-modal.dialog-modal-no-headline .modal-body {border-top-left-radius:4px; border-top-right-radius:4px;}

/*
--------------------------------------------------------------------- MAGNIFIC POPUP ZOOM EFFECT

------------------------------------------------------------------------------------------------
*/


.mfp-zoom-in {

    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transform: scale(0.8);
        transition-duration: .2s;
        transition-timing-function: ease-in-out;
    }

    &.mfp-bg {
        opacity: 0;
        transition-duration: .3s;
        transition-timing-function: ease-in-out;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }
        &.mfp-bg {
            opacity: 0.7;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: scale(0.5);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }

}

.mfp-slide-up {

    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transform: translateY(40px);
        transition-duration: .25s;
        transition-timing-function: cubic-bezier(0,0,.5,1);
    }

    &.mfp-bg {
        opacity: 0;
        transition-duration: .2s;
        transition-timing-function: linear;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: translateY(0);
        }
        &.mfp-bg {
            opacity: 0.7;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: translateY(40px);
            transition-duration: .2s;
            transition-timing-function: ease-in;
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }

}


/*
----------------------------------------------------------------------------------- PRINT STYLES

------------------------------------------------------------------------------------------------
*/
@media print {
    .mfp-wrap,
    .mfp-bg {
        display: none;
    }
}


/*
---------------------------------------------------------------------------- SCROLL WITHIN MODAL

------------------------------------------------------------------------------------------------
*/


.mfp-bg {
    position: fixed!important; // Sometimes magnific doesn't add this properly
}

/*

.mfp-bg {
    // min-height:5000px; // Might not need this
}

.mfp-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    //calc(100% - 40px);
}

.mfp-centered .mfp-content {
    justify-content: center;
}

.mfp-modal {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: calc(100% - 60px);
    pointer-events: auto;
    margin-top: 20px;
}

.modal-body {
    //overflow-y: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1 1 auto;
    // overflow-y might need to be 'scroll'
    -webkit-overflow-scrolling: touch;
}
*/
