/* HAMBURGER / CROSS */

.menu-btn {
    padding: 12px 6px 7px 6px;
    height:44px;
    display: inline-block;
    cursor: pointer;
    //transition-property: opacity, filter;
    //transition-duration: 0.15s;
    //transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

.menu-btn-box {
    width: 32px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.menu-btn-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.menu-btn-inner,
.menu-btn-inner::before,
.menu-btn-inner::after {
    width: 32px;
    height: 4px;
    background-color: $primary;
    border-radius: 0;
    position: absolute;
    //transition-property: transform;
    //transition-duration: 0.15s;
    //transition-timing-function: ease;
}

.menu-btn-inner::before, .menu-btn-inner::after {
    content: "";
    display: block;
}

.menu-btn-inner::before {top: -8px;}
.menu-btn-inner::after {bottom: -8px; }


/* ANIMATION */
.menu-btn-inner {
    //transition-duration: 0.075s;
    //transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.menu-btn-inner::before {
    //transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.menu-btn-inner::after {
    //transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* STATE CHANGE */

.mob-nav-open .menu-btn .menu-btn-inner {
    transform: rotate(45deg);
    //transition-delay: 0.12s;
    //transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.mob-nav-open .menu-btn .menu-btn-inner::before {
    top: 0;
    opacity: 0;
    //transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.mob-nav-open .menu-btn .menu-btn-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    //transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
