/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK FRONT CSS - site_base.css
------------------------------------------------------------------------------------------------
  Created:  01-10-2017
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/


/*
--------------------------------------------------------------------------------------- clrfix

------------------------------------------------------------------------------------------------
*/

.clr {@include clrfix;}


/*
------------------------------------------------------------------------------------ HTML / BODY

------------------------------------------------------------------------------------------------
*/



html {
    font-size: 16px;
    line-height: 1.5;

    font-family: $font-family-body;
    font-weight:$font-light;

    background-color: $black;
    color: $white;

    //@include font-smoothing(on)
    min-width: 300px;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
}



/* apply a natural box layout model to all elements, but allowing components to change */
html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }


/*
---------------------------------------------------------------------------------- TEXT DEFAULTS

------------------------------------------------------------------------------------------------
*/

/* Base Text */
p {
    margin: 0 0 1rem 0;
    padding:0 4px 0 0;
    @include fontSize(15px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(16px);
    }
    line-height:1.4;
    position:relative;
    //@include font-smoothing(off);
    font-weight: $font-light;
}

b, strong {
    font-family: $font-family-body-strong;
    font-weight: normal;
    //font-weight: $font-medium;
}


/* Links */
a {color:$link-primary; margin: 0; padding: 0; outline:none; text-decoration: none; word-wrap: break-word; font-weight:$font-regular;}
a:link, a:visited {}
a:hover, a.active {text-decoration: none; color:$link-primary-hover;}
a:focus {outline: none;}

a.link-blank {
    color:$white;
}

a.link-blank:hover, a.link-blank.active {
    color:$white;
}
/* Headings */
h1, h2, h3, h4, h5, h6,
.h-1, .h-2, .h-3, .h-4, .h-5, .h-6 {
    font-family: $font-family-thin;
    line-height: 1.3;
    font-weight: normal;
    color:$white;
    margin: 0 0 1rem 0;
    position:relative;
    @include font-smoothing(on);
}

h1 a, h2 a, h3 a,
.h-1 a, .h-2 a, .h-3 a {text-decoration:none; color:$white;}

h1 a:hover, h2 a:hover, h3 a:hover,
.h-1 a:hover, .h-2 a:hover, .h-3 a:hover {text-decoration:none;}

h1, .h-1 {@include fontSize(28px); letter-spacing: 1px;}
h2, .h-2 {@include fontSize(26px);}
h3, .h-3 {@include fontSize(24px);}
h4, .h-4 {@include fontSize(20px);}
h5, .h-5 {@include fontSize(17px);}
h6, .h-6 {@include fontSize(16px);}

//h3 span {@include fontSize(18px);}

h1 span, h2 span, h3 span, h4 span, h5 span,
.h-1 span, .h-2 span, .h-3 span, .h-4 span, .h-5 span {
    font-weight:$font-light;
}

.h-1-hero {
    text-transform: uppercase;
    line-height: 1.1;
}

@media screen and (min-width: $break-tablet) {//  720/16
    h1, .h-1 {@include fontSize(48px);}
    h2, .h-2 {@include fontSize(36px);}
    h3, .h-3 {@include fontSize(32px);}
    h4, .h-4 {@include fontSize(26px); line-height: 1.2;}
    h5, .h-5 {@include fontSize(19px);}
    h6, .h-6 {@include fontSize(16px);}

    .h-1-hero {@include fontSize(46px);}

    //h3 span {@include fontSize(24px); line-height:1;}

}

@media screen and (min-width: $break-desktop) {//  720/16
    .h-1-hero {@include fontSize(56px); }
}


.h-primary {color:$primary;}
.h-secondary {color:$secondary;}

/* LISTS */
ul,ol,dl {
    margin: 0 0 1em 0;
    @include fontSize(15px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(16px);
    }
    line-height:1.5;
    -webkit-backface-visibility:hidden;
    //@include font-smoothing(on);
    font-weight: $font-light;
}

li {padding-bottom:4px; margin: 0;}

ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0;
    @include fontSize(14px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(16px);
    }
}

dl {font-weight: $font-light;}
dt {font-weight: $font-medium;}
dd {margin-bottom: 1rem; margin-left: 0; }

/* QUOTES */
blockquote {margin: 0 0 1rem;}


/* IMAGES */
img {margin: 0 0 1em 0; padding: 0;}
img {max-width: 100%; height: auto; width: auto\9; /* ie8 */}

svg { pointer-events: none; } // https://bugs.jquery.com/ticket/11352
svg:not(:root) {overflow: hidden;}

/* SHOW HIDE HELPERS */
.hide-mobile {
    @media screen and (max-width: $break-tablet) {//  720/16
        display:none!important;
    }
}

.show-mobile {
    @media screen and (min-width: $break-tablet) {//  720/16
        display:none!important;
    }
}

/* TEXT HELPERS */
.hidden {display:none!important;}

.sr-only,
.skip-link {
    @include sr-only;
}

.text-smaller {
    @include fontSize(13px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(14px);
    }
}
.text-larger {
    @include fontSize(16px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(18px);
    }
}

.text-largest {
    @include fontSize(16px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(26px);
    }
}

.text-primary {color:$primary;}
.text-secondary {color:$primary;}
.text-warning {color:$warning;}
.text-danger {color:$danger;}

.centered {text-align: center;}

.hidden-tablet-down {display:none;}
@media screen and (min-width: $break-tablet) {
    .hidden-tablet-down {display:block;}
    .hidden-tablet-up {display:none;}
}

/* ANIMATION HELPER CLASSES */
.animate-opacity {
    transition: opacity 0.2s linear;
}

.animate-opacity.fade-in,
.animate-opacity.animate-show {
    opacity:1;
}
.animate-opacity.fade-out,
.animate-opacity.animate-hide {
    opacity:0;
}

/* HR */
.hr {
    clear:both;
    border:0;
    border-top: 1px solid $primary;
    color: $primary;
    background-color: $primary;
    height: 1px;
    margin: 16px 0 22px 0;
    overflow: hidden;
}

.hr-dashed {
    border-top: 1px dashed $primary;
}

.hr-darker {
    color: $primary;
    border-color: $primary;
}

.hr-thick {
    border-top-width: 2px;
}

.hr.hr-no-margin {
    margin:0;
}

.hr.hr-mb {
    margin-bottom: 30px;
}

.hr.hr-mb-double {
    margin-bottom: 60px;
}

.hr.hr-max {
    max-width: 100px;
    margin-left: auto;
    margin-right: auto;

}

code {
    background-color: $form-input-bg-alt;
    display:inline-block;
    padding: 0 6px;
    border-radius:3px;
}


/* BLOCKQUOTES */
blockquote {
    background-color: whitesmoke;
    border-left: 5px solid #dbdbdb;
    padding: 1.25em 1.5em;
    margin-bottom: 1.5em;
}
.blockquote footer::before {
    content: "\2014 \00A0";
}

/* CONTENT SPACING */

.main p + h1,
.main p + h2,
.main p + h3 {
    padding-top: 14px;
}


/* SVG ICONS */

.svg-icon {
    display: block;
    width:16px;
    height:16px;
    fill: currentColor;
    vertical-align: top;
}


// Default ratio for Icons

.icon-ratio {
    width: 18px;
    min-width:18px; // don't collapse in flexbox
    position: relative;
}

.icon-ratio:before {
    display: block;
    content: "";
    width: 100%;
    padding-top:100%;
}

.icon-ratio {
    position: relative;
}

.icon-ratio .svg-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width:100%;
    height:100%;
}


/*
----------------------------------------------------------------------------------------- LAYOUT

------------------------------------------------------------------------------------------------
*/

.row {
    @include clrfix; 
    max-width: 80rem; 
    margin:0 auto;
    padding: 0 15px; 
    @media screen and (min-width: $break-tablet) {//  720/16
        padding: 0 20px; 
    }
    @media screen and (min-width: $break-desktop) {//  720/16
        padding: 0 50px; 
    }
}
.row .row {padding-left: 0; padding-right: 0;}

.row-thin {max-width: 70rem;}
.row-thin-mid {max-width: 52rem;}
.row-thinner {max-width: 42rem;}
.row-thinnest {max-width: 30rem;}

section,
article {
    padding: 40px 0;
}


/* SECTION HELPERS */

.section-collapse {padding-top:0; padding-bottom:0;}
.section-collapse-top {padding-top:0;}
.section-collapse-bottom {padding-bottom:0;}

.section-tall {padding: 100px 0;}
.section-tall-top {padding-top: 100px;}
.section-tall-bottom {padding-bottom: 100px;}

.section-xtall {padding: 80px 0;}
.section-xtall-top {padding-top: 120px;}
.section-xtall-bottom {padding-bottom: 120px;}

.section-short {padding-top:20px; padding-bottom:20px;}
.section-short-top {padding-top:20px;}
.section-short-bottom {padding-bottom:20px;}

/* GENERAL HELPERS */

.shadow {box-shadow: 0 2px 4px rgba(0,0,0,.2);}

.bg-primary {background-color: $primary;}
.bg-secondary {background-color: $secondary;}
.bg-grey-paler {background-color: $bg-grey-paler;}

.hidden {display:none;}

/*
.gold-border {border-top: 3px solid #f0b323;}
.gold-border-thick {border-top: 5px solid #f0b323;}
*/

.cta-block {
    padding-top:20px;
}

.cta-block .btn {
    margin-bottom: 0;
}

.cta-block .btn:last-child {
    margin-right:0;
}

.cta-block-helper {
    padding-top:20px;
    margin-bottom:0;
}

/*
----------------------------------------------------------------------------------------- PAGE DIVIDER

------------------------------------------------------------------------------------------------
*/

.divider {
    position: relative;
    margin-bottom: 40px;
}
.divider:before {
    content:'';
    display: block;
    width:100%;
    height:1px;
    background-color: $text-paler;
    position: absolute;
    top:50%;
    left:0;
    margin-top:-1px;
    z-index: 1;
}

.divider .icon-divider {
    position: relative;
    z-index: 2;
    width:58px;
    margin: 0 auto;
    color:$text-paler;
    background-color: $white;
    padding-left: 6px
}

.icon-divider .svg-icon {
    width: 100%;
    height: 100%;
}

.icon-divider .icon-ratio {width:46px;}
$cc-ratio: calcRatio(1024,520);
.icon-divider .icon-ratio:before {padding-top:$cc-ratio;}

/*
------------------------------------------------------------------------------------ 2 COLUMN DL

------------------------------------------------------------------------------------------------
*/

.dl-2-col {
    display: flex;
    flex-flow: row wrap;
    padding-left: 0;
}

.dl-2-col dt,
.dl-2-col dd {
    word-wrap: break-word;
    margin: 0;
    padding-bottom: 6px;
}

.dl-2-col dt {
    width: 100px;
    @media screen and (min-width: $break-landscape) {//  720/16
        width: 120px;
    }
    //display: flex;
    //justify-content: flex-end;
}

.dl-2-col dd {
    width: calc(100% - 100px);
    @media screen and (min-width: $break-landscape) {//  720/16
        width: calc(100% - 120px);
    }
    padding-left:10px;
}

.dl-2-col.dl-2-col-left dt {
    justify-content: flex-start;
}

.definition-table {
    margin: 0 0 1rem 0;
    padding:0;
    //
    @include fontSize(16px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(18px);
    }
    //
    line-height:1.5;
    border:0;
}

.definition-table.text-smaller {
    @include fontSize(14px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(16px);
    }
}

.definition-table tr td {
    vertical-align: top;
    padding-bottom: 4px;
}

.definition-table tr td:first-child {
    padding-right:16px;
}

.definition-table tr td:last-child {
    font-weight: $font-medium;
}


/*
-------------------------------------------------------------------------------------- HOME PAGE

------------------------------------------------------------------------------------------------
*/

.tagline {
    text-align: center;
    color: $primary;
    border:1px solid $primary;
    border-left:0;
    border-right:0;
    //padding: 80px 0;
    padding: 20px 0;
    @media screen and (min-width: $break-tablet) {//  720/16
        //padding: 130px 0;
        padding: 30px 0;
    }
    //margin-bottom:40px;
}

.tagline br {
    display: none;
    @media screen and (min-width: $break-landscape) {//  720/16
        display: inline;
    }
}

.tagline p {
    color: $primary;
}

.tagline h2 {
    @media screen and (min-width: $break-tablet) {//  720/16
        //@include fontSize(34px);
        //line-height: 1.4;
    }
}

.tagline .row p:last-child,
.tagline .row h3:last-child {
    margin-bottom:0;
}


/*
------------------------------------------------------------------------------------------- MISC

------------------------------------------------------------------------------------------------
*/


.highlight-block {
    background-color: $bg-grey-paler;
    border-radius:4px;
    padding:20px 20px 4px 20px;
}

.highlight-block.highlight-block-alt {
    background-color: transparent;
    border:1px solid $primary;
}



/*
------------------------------------------------------------------------------------ IMAGE RATIO

------------------------------------------------------------------------------------------------
*/
.crop-img-square {
    width:100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
}
.crop-img-square img {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: cover;
    margin:0;
}

/*
--------------------------------------------------------------------=---------------- PAGE TITLE

------------------------------------------------------------------------------------------------
*/

.page-title {
    padding-top:10px;
    border-bottom: 1px solid $primary;
}

.page-title h1 {
    //margin-bottom: 0;
}

.page-title.page-title-centered {
    border-bottom: 0;
    text-align: center;
    position: relative;
}

/*
.page-title.page-title-centered::after {
    content:'';
    width:200px;
    height:1px;
    position: absolute;
    bottom:0;
    left:50%;
    transform: translateX(-50%);
    background-color: $primary;
}
*/