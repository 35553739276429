/*
--------------------------------------------------------------------------------- TOGGLE BUTTONS

------------------------------------------------------------------------------------------------
*/

/*
.js .btn-group-toggle > .btn input[type="radio"],
.js .btn-group-toggle > .btn-group > .btn input[type="radio"] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}
*/


/* MAKE LIKE BUTTON GROUP */

.toggle-btn-group {
    display: flex;
    width:100%;
    position: relative;
}

.form-inline .toggle-btn-group {
    display: inline-flex;
    width: auto;
}

.toggle-btn-group.toggle-btn-group-centered {
    justify-content: center;
}

.toggle-btn-group > .toggle-btn-radio:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: $btn-default-bg;
}

.toggle-btn-group > .toggle-btn-radio:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.toggle-btn-group .toggle-btn-radio + .toggle-btn-radio  > .btn {
    //margin-left: -1px;
}

.toggle-btn-group .btn {
    margin:0;
}

/* DIASBLE ALL RADIOS */
.toggle-btn-group.disabled::before {
    content:'';
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    //
    background-color: #fafcff;
    //
    opacity:0;
    z-index: 2;
    //
    transition: opacity 0.2s linear;
}

.toggle-btn-group.disabled.animate::before {
    opacity:0.4;
}


/* HIDE RADIOS */

.toggle-btn-radio {position: relative;}

.toggle-btn-radio input[type=radio]:checked,
.toggle-btn-radio input[type=radio]:not(checked) {
    width: 22px;
    height: 22px;

    position: absolute;
    top:0;
    left:50%;

    margin: 0 0 0 -11px;
    padding: 0;

    z-index: -1;
    cursor: pointer;
    //
    opacity: 0;
}


/* COLOURS AND STATES */

.toggle-btn-group > .toggle-btn-radio .btn {
    background-color: $btn-default-bg;
    border:1px solid $btn-default-border;
    color: $text-main;
}

// HOVER / FOCUS
.toggle-btn-group > .toggle-btn-radio .btn:hover,
.toggle-btn-group > .toggle-btn-radio input[type=radio]:focus + label.btn {
    background-color:$btn-default-hover-bg;
    border-color:$btn-default-hover-border;
}

// Checked
.toggle-btn-group > .toggle-btn-radio input[type=radio]:checked + label.btn {
    background-color:$primary;
    border-color:$primary;
    color:$white;
    box-shadow: inset 0px 0px 6px 1px rgba(0,0,0,0.15);
}


.toggle-btn-group > .toggle-btn-radio.toggle-btn-radio-success input[type=radio]:checked + label.btn {
    background-color:$success;
    border-color:$success;
    color:$white;
}

.toggle-btn-group > .toggle-btn-radio.toggle-btn-radio-danger input[type=radio]:checked + label.btn {
    background-color:$danger;
    border-color:$danger;
    color:$white;
}


// FADED COLOUR

/*
.toggle-btn-group > .toggle-btn-radio.toggle-btn-radio-success-fade .btn {
    background-color:$alert-success-bg;
    border-color:$alert-success-bg-dark;
}

.toggle-btn-group > .toggle-btn-radio.toggle-btn-radio-danger-fade .btn {
    background-color:$alert-danger-bg;
    border-color:$alert-danger-bg-dark;
}

.toggle-btn-group > .toggle-btn-radio.toggle-btn-radio-success-fade .btn:hover,
.toggle-btn-group > .toggle-btn-radio.toggle-btn-radio-danger-fade .btn:hover {
    border-color:$btn-default-hover-border;
}
*/