/*
---------------------------------------------------------------------------------- TABLE ELEMENTS

------------------------------------------------------------------------------------------------
*/

table {border-spacing: 0; border-collapse: collapse;}

.table {width: 100%; max-width: 100%; margin-bottom: 20px; @include fontSize(16px);}

.table th {text-align: left;}

.table > thead > tr > th {border-bottom: 1px solid $border-grey-pale; vertical-align: bottom;}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-top: 1px solid $border-grey-pale;
    line-height: 1.4;
    padding: 8px 8px;
    vertical-align: top;
}

.table > thead > tr > td {
    font-weight: $font-regular;
}

.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

table .checkbox,
table .radio {
    margin-top: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {background-color: $bg-grey-paler;}

.table-bordered {border: 1px solid $border-grey-pale;}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
	background-color: $bg-grey-paler; border-bottom-width: 1px;
}

.table-bordered.table-striped>tbody>tr:nth-of-type(odd) {background-color: transparent;}
.table-bordered.table-striped>tbody>tr:nth-of-type(even) {background-color: $bg-grey-paler;}


/* MOBILE TABLES */

.table thead {display: none;}
.table tr {display: block; border-bottom: 1px solid $border-grey-pale;}
.table td {display: block; text-align: right; /*border-bottom: 1px dotted $border-grey-pale;*/}
.table td:last-child {border-bottom: 0;}
.table td:before {content: attr(data-label); float: left; font-weight:600;}

.table tfoot td {text-align: left;}

.table .cta {padding-top:4px; padding-bottom:4px;}

.table .even td {border-color: $border-grey-pale; background-color: $bg-grey-paler;}


@media screen and (min-width: $break-tablet) {//  720/16

	.table thead {display: table-header-group;}
	.table tr {display: table-row;}
	.table td {display: table-cell; text-align: left; border-bottom:none;}
	.table td:before {display:none;}
	.table tr {border-bottom: none;}
	.table tr th {padding: 14px 14px;}
	.table tr td {padding: 16px 14px;}

	.table .cta {padding-top:0; padding-bottom:0;}

	.table .col-date {width:200px;}
	.table .col-cta { width:250px;}
	.table .col-cta .cta {float:right;}

    .table.table-flush > thead > tr > th:first-child,
    .table.table-flush > thead > tr > td:first-child,
    .table.table-flush > tbody > tr > th:first-child,
    .table.table-flush > tbody > tr > td:first-child,
    .table.table-flush > tfoot > tr > th:first-child,
    .table.table-flush > tfoot > tr > td:first-child, {
        padding-left: 0;
    }

    .table.table-flush > thead > tr > th:last-child,
    .table.table-flush > thead > tr > td:last-child,
    .table.table-flush > tbody > tr > th:last-child,
    .table.table-flush > tbody > tr > td:last-child,
    .table.table-flush > tfoot > tr > th:last-child,
    .table.table-flush > tfoot > tr > td:last-child, {
        padding-right: 0;
    }


    .table td.cell-centered {text-align: center;}
    .table td.cell-right {text-align: right;}

} // end min-width: 720px


// Force desktop layout on mobile
.table.force-tablet thead {display: table-header-group;}
.table.force-tablet tr {display: table-row;}
.table.force-tablet td {display: table-cell; text-align: left; border-bottom:none;}
.table.force-tablet td:before {display:none;}
.table.force-tablet tr {border-bottom: none;}
.table.force-tablet tr th {padding: 14px 14px;}
.table.force-tablet tr td {padding: 16px 14px;}

.table.force-tablet .cta {padding-top:0; padding-bottom:0;}

.table.force-tablet .col-date {width:200px;}
.table.force-tablet .col-cta { width:250px;}
.table.force-tablet .col-cta .cta {float:right;}

.table.force-tablet.table-flush > thead > tr > th:first-child,
.table.force-tablet.table-flush > thead > tr > td:first-child,
.table.force-tablet.table-flush > tbody > tr > th:first-child,
.table.force-tablet.table-flush > tbody > tr > td:first-child,
.table.force-tablet.table-flush > tfoot > tr > th:first-child,
.table.force-tablet.table-flush > tfoot > tr > td:first-child, {
    padding-left: 0;
}

.table.force-tablet.table-flush > thead > tr > th:last-child,
.table.force-tablet.table-flush > thead > tr > td:last-child,
.table.force-tablet.table-flush > tbody > tr > th:last-child,
.table.force-tablet.table-flush > tbody > tr > td:last-child,
.table.force-tablet.table-flush > tfoot > tr > th:last-child,
.table.force-tablet.table-flush > tfoot > tr > td:last-child, {
    padding-right: 0;
}


.table.force-tablet td.cell-centered {text-align: center;}
.table.force-tablet td.cell-right {text-align: right;}


td .btn {
    margin-bottom: 0;
}

td .file-icon img {
    max-width:30px;
    margin-bottom: 0;
}

// Colouring

tr.danger {
    background-color:rgba(237,85,101,0.4);
}

tr.success {
    background-color:rgba(90,181,74,0.4);
}
