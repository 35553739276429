/*
------------------------------------------------------------------------ HTML FILE UPLOAD FIELDS

------------------------------------------------------------------------------------------------
*/


/* https://www.abeautifulsite.net/whipping-file-inputs-into-shape-with-bootstrap-3 */

.btn-file { position: relative; overflow: hidden; width:auto;}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

label.input-group-btn {margin:0; padding:0;}

.input-group input[readonly]:focus{ border-color:$input-border;}

.file-upload-input {position:relative;}
.file-upload-input .del {display: block; color: $text-main; width: 20px; height: 20px; position:absolute; top:8px; right:0; z-index:50; opacity:0.6; display:none;}
.file-upload-input .del:hover {opacity:1;}
.file-upload-input input[type=text] {padding-right:30px;}


// Hide input if file already exists
.file-upload-with-image .input-group {display:none;}

// Hidden as this is js-targeted by our x button
.file-upload-remove {width:100%; clear:both; display: none;}
