/*
------------------------------------------------------------------------------------------- JOBS

------------------------------------------------------------------------------------------------
*/



.jobs-search {
    width:100%;
    position: relative;
    overflow: visible;
    //
    //display:flex;
    //flex: 0 1 auto;
    //flex-direction: row;
    //flex-wrap: wrap;
    //justify-content: space-between;
}

.jobs-search .jobs-search-fields {
    //-ms-flex-preferred-size: span(3);
   // flex-basis: span(3);
   // max-width: span(3);
    display:flex;
    flex-wrap: wrap;
    @media screen and (min-width: $break-tablet) {//  720/16
        flex-wrap: nowrap;
    }
}

.jobs-search .jobs-search-fields .jobs-search-fields-block {
    margin-right:20px;
    max-width: 40%;
    padding-right:1px;
    overflow: visible;
}

.jobs-search .chosen-container {
    
}

.jobs-search .jobs-search-results {
   // -ms-flex-preferred-size: span(9);
    //flex-basis: span(9);
    //max-width: span(9);
   // padding-left:20px;
    //
   // min-height:100px;
   //
   //max-width: 1020px;
}

.jobs-search .jobs-search-fields-block {
    @include clrfix;
    margin-bottom:10px;
}

.jobs-search .jobs-search-fields .custom-check label,
.jobs-search .jobs-search-fields .custom-radio label {
    display:block;
}

.jobs-search .jobs-search-results-count {
    line-height:1;
}

.jobs-search .jobs-search-results-count-block {
    display:flex;
}

.jobs-search .css-spinner {
    margin-left:auto;
    transition:opacity 0.2s linear;
    opacity:0;
}


/* DISABLE / LOADING */

.jobs-search.searching-fade .css-spinner {
    opacity:1;
}

.jobs-search-results-inner {
    position:relative;
}

.jobs-search-results-inner::after {
    content: '';
    background-color:$black;
    opacity:0;
    //
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:0;
    //
    z-index:10;
    //
    transition: opacity 0.15s linear;
}

.jobs-search.searching .jobs-search-results-inner::after{
    height:auto;
    bottom:0;
}

.jobs-search.searching.searching-fade .jobs-search-results-inner::after {
    opacity:0.5;
}

.jobs-search.fade-out .jobs-search-results-inner::after {
    opacity:1;
}


/* JOB ITEMS */

.job-item {
    //background-color: $bg-grey-paler;
    border:1px solid $border-grey-paler;
    padding:14px;
    @media screen and (min-width: $break-tablet) {//  720/16
        padding:20px;
    }
    margin-bottom: 20px;
    //max-width: 1020px;
    position: relative;
    //border-radius: 3px;
}

.job-item h3 {
    padding-right: 46px;
}

.job-item .job-icon {
    position: absolute;
    top:14px;
    right:14px;
    @media screen and (min-width: $break-tablet) {//  720/16
        top:20px;
        right:20px;
    }
}

.job-item .job-item-apply-btn {
    margin-bottom: 0;
}

.job-item-content {
    padding-right:34px;
}

.job-item-content p:last-child {
    margin-bottom: 0;
}

/* TRUNCATED JOB ITEMS */

.js .job-item .truncated-block-inner {
    max-height:110px;
    //135px;
}
.js .job-item .truncated-block-inner.truncated-block-inner-fade:after {
    height:50px;
    //
    //background: linear-gradient(to bottom, red 0%,orange 95%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 95%);
}

@media screen and (min-width: $break-tablet) {//  720/16

    .job-items-grid {
        display: flex;
        flex-wrap: wrap;
    }

    .job-items-grid .job-item {
        -ms-flex-preferred-size: span(6, $grid-tablet);
        flex-basis: span(6, $grid-tablet);
        max-width: span(6, $grid-tablet);
        margin-right:gutter($grid-tablet);
    }

    .job-items-grid .job-item:nth-child(2n) {
        margin-right:0;
    }

}

@media screen and (min-width: $break-desktop) {//  720/16
    
    .job-items-grid .job-item {
        -ms-flex-preferred-size: span(4, $grid-tablet);
        flex-basis: span(4, $grid-tablet);
        max-width: span(4, $grid-tablet);
        margin-right:gutter($grid-tablet);
    }

    .job-items-grid .job-item:nth-child(2n) {
        margin-right:gutter($grid-tablet);
    }

    .job-items-grid .job-item:nth-child(3n) {
        margin-right:0;
    }

}

/* ACTION BLOCK  */
.job-item-actions {
    padding-top:24px;
    display: flex;
}

.job-item-actions-apply{
    flex:1;
}

/* SHARE LINKS */

.job-item-share {
    //@include: 10px;
    display:flex;
    align-items: center;
    @media screen and (min-width: $break-tablet) {//  720/16
        //position: absolute;
        //right:20px;
        //bottom:-6px;
        //text-align: center;
    }
}
.job-item-share p {
    margin:0;
}

.job-item-share .social-nav li {
    padding-bottom:0;
}

.job-item-share .icon-social {
    padding-top:0;
    padding-bottom:0;
    height:20px;
}

.job-item-share .icon-social.social-email {
    padding-top:4px;
}

@media screen and (min-width: $break-tablet) {//  720/16
    .job-item-share .social-nav li {
        float:none;
        display: inline-block;
    }
}



/* ICONS */

$propellor-ratio: calcRatio(100,95);
$house-ratio: calcRatio(100,84);
$sail-ratio: calcRatio(100,88);
$computer-ratio: calcRatio(100,83);
$chauffeur-ratio: calcRatio(100,100);
$pram-ratio: calcRatio(100,100);
$ski-ratio: calcRatio(100,100);

.icon-propellor,
.icon-house,
.icon-sail-boat,
.icon-computer,
.icon-chauffeur,
.icon-pram,
.icon-ski,

.icon-propellor .icon-ratio,
.icon-house .icon-ratio,
.icon-sail-boat .icon-ratio,
.icon-computer .icon-ratio,
.icon-chauffeur .icon-ratio,
.icon-pram .icon-ratio,
.icon-ski .icon-ratio {
    width:28px;
}

.icon-propellor .svg-icon,
.icon-house .svg-icon,
.icon-sail-boat .svg-icon,
.icon-computer .svg-icon,
.icon-chauffeur .svg-icon,
.icon-pram .svg-icon,
.icon-ski .svg-icon {
    width: 100%;
    height: 100%;
}

.icon-propellor .icon-ratio:before {
    padding-top:$propellor-ratio;
}

.icon-house .icon-ratio:before {
    padding-top:$house-ratio;
}

.icon-sail-boat .icon-ratio:before {
    padding-top:$sail-ratio;
}

.icon-computer .icon-ratio:before {
    padding-top:$computer-ratio;
}

.icon-chauffeur .icon-ratio:before {
    padding-top:$chauffeur-ratio;
}

.icon-pram .icon-ratio:before {
    padding-top:$pram-ratio;
}

.icon-ski .icon-ratio:before {
    padding-top:$ski-ratio;
}




/* MISC */
.job-details-bordered {
    border: 1px solid $form-input-border;
    background-color: $form-input-bg;
    padding:10px 12px;
    margin: 0 0 1rem 0;
}

.job-details-bordered h5 {
    margin-bottom:4px;
}

.job-details-bordered p:last-child {
    margin:0;
}

.job-item-applied .icon-ratio{
    width:24px;
    float:left;
    margin-right:6px;
    color:$primary;
}