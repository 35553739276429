/*
 *
 *   Chosen Selects
 *   Version: 1.0.2
 *
 */


 /*
--------------------------------------------------------------------------------- CHOSEN SELECTS

------------------------------------------------------------------------------------------------
*/

//-------- Ember overrides for chosen selects


/* CHOSEN PLUGIN */
.chosen-container-single .chosen-single {
  background: #ffffff;
  box-shadow: none;
  -moz-box-sizing: border-box;
  border-radius: 4px;
  cursor:pointer!important;
  height: auto !important;
  line-height:20px;
  margin: 0;
  min-height: 30px;
  overflow: hidden;
  padding: 6px 0 6px 8px;
  position: relative;
  width: 100%;
}


.chosen-container-multi .chosen-choices li.search-choice {
  background: #f1f1f1;
  border: 1px solid #e5e6e7;
  border-radius: 2px;
  box-shadow: none;
  color: #333333;
  cursor: default;
  line-height: 13px;
  margin: 6px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  position: relative;
}

.chosen-container .chosen-results {
  //color: #ffffff;
}


.chosen-container-multi .chosen-choices .search-field input[type=text] {color:$text-main;}

.chosen-container-single .chosen-search input[type="text"] {outline:none;}

//.chosen-container-multi .chosen-choices .search-field input[type="text"],
.chosen-container-active .chosen-choices li.search-field input[type="text"]{
    color: #fff!important;
    padding-left:8px;
}

.chosen-container-single .chosen-default,
.chosen-container-multi .chosen-choices .search-field input.default{
    color:#999999;
    //color:#fff!important;
}



.chosen-container .chosen-results li.group-result {color:$text-main;}


.chosen-container .chosen-results li.highlighted {background-color: $primary;}

.chosen-container-active.chosen-with-drop .chosen-single {border-color:$primary;}

.chosen-container-active .chosen-choices {border-color:$primary;}

.chosen-container-active .chosen-single {border-color:$primary;}

.chosen-container-single .chosen-single abbr{
    right:18px!important;
}

// Kill outline
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-active .chosen-single,
.chosen-container-active.chosen-with-drop .chosen-single {
    -webkit-box-shadow:none;
    box-shadow:none;
    outline:none;
}


// Chosen multi doesn't want to play nice with form-inline or when hidden on init

.chosen-container-multi .chosen-choices {min-width:165px;}

.form-inline .chosen-container-multi .search-field,
.form-inline .chosen-container-multi .search-field input.default {
  min-width:150px;
}

// Force left align (dropdown may appear in an align right inline form)
.chosen-container {text-align: left;}

// Make disabled chosen look like Insipina Disabled fields
.chosen-disabled {opacity:1!important; }

.chosen-container-single.chosen-disabled .chosen-single,
.chosen-container-multi.chosen-disabled .chosen-choices {
	background-color:#eee; cursor: not-allowed!important;
}

.chosen-container-single.chosen-disabled .chosen-single:focus {outline:none;}

.chosen-container-single.chosen-disabled .chosen-single abbr,
.chosen-container-single.chosen-disabled .chosen-single div {
	display:none;
}

.chosen-container-multi.chosen-disabled .chosen-choices {box-shadow:none;}

.chosen-container-multi.chosen-disabled .chosen-choices .search-choice .search-choice-close {display:none;}
.chosen-container-multi.chosen-disabled .chosen-choices li.search-choice {cursor: not-allowed!important; padding-right:5px; background-color:#f3f4f4; border-color:$input-border;}
.chosen-container-multi.chosen-disabled .chosen-choices .search-field input[disabled] {cursor: not-allowed!important;}

// Add a bit more space between x and selected option
.chosen-container-single .chosen-single span {margin-right: 36px;}

// Reposition search icon

.chosen-container-single .chosen-search input[type=text] {background: none;}
.chosen-container-single .chosen-search:after {content:''; width:12px; height:12px; position:absolute; top:13px; right:12px; background: url('../img/chosen-sprite.png') -34px -25px no-repeat;}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx) {
  .chosen-container-single .chosen-search:after {
        background-image: url("../img/retina/chosen-sprite.png") !important;
        background-size: 52px 37px !important;
  }
  .chosen-container-single .chosen-search input[type=text] {background: none!important;}
}

.chosen-container-single.chosen-container-single-nosearch .chosen-search:after {display:none;}

// Small versions
.form-horizontal .chosen-sm {padding-top:2px;}
.chosen-sm .chosen-container-single .chosen-single {padding-top:4px; padding-bottom:4px;}
.chosen-sm .chosen-container-single .chosen-single abbr {top:10px;}
.chosen-sm .chosen-container-single .chosen-single div b {background-position:0 5px;}

.form-horizontal .chosen-xs {padding-top:4px;}
.chosen-xs .chosen-container {font-size: 13px;}
.chosen-xs .chosen-container-single .chosen-single {padding-top:3px; padding-bottom:3px; line-height:18px; min-height:27px;}
.chosen-xs .chosen-container-single .chosen-single abbr {top:8px;}
.chosen-xs .chosen-container-single .chosen-single div b {background-position:0 3px;}

// Validation

.error .chosen-container-single .chosen-single,
.error .chosen-container-multi .chosen-choices {
  border-color:$danger; border-width:2px;
}

/*.valid .chosen-container-single .chosen-single {
.valid .chosen-container-multi .chosen-choices {
  border-color:$success; border-width:2px;
}*/

.error .chosen-container-single .chosen-single abbr {
//.valid .chosen-container-single .chosen-single abbr {
  top:14px;
}

.error .chosen-container-single .chosen-single div:before {
//.valid .chosen-container-single .chosen-single div:before {
  top: 20px;
}

.error .chosen-container-single .chosen-single span {
//.valid .chosen-container-single .chosen-single span {
  padding-top: 0;
}


// Add new option to select with AJAX form
.select-with-add-option {position: relative; padding-right: 40px;}
.select-with-add-option .btn {position: absolute; top:0; right:0;}

.js-select-add-option-form {display: none;}

.chosen-container.chosen-with-new-option .chosen-results .no-results:after {content:' (Click to add new option)'}
.chosen-container.chosen-with-new-option .chosen-results .no-results:hover {cursor: pointer;}

.js-select-add-option-results {padding-bottom: 10px;}

// Edit select option with AJAX form
.select-edit-option-buttons {padding-top: 4px;}
.select-edit-option-buttons a {margin-right: 10px;}

// Min width for chosen selects in inline forms
.form-inline .select select {min-width: 120px;}

.form-inline-min-select .select {min-width: 140px;}
.form-inline-min-select .select select {width: auto;}


// Chosen has updated but bootstrap chosen hasn't so here are the chosen.css updates
.chosen-container .chosen-drop {
    clip:auto;
    display: none;
}
.chosen-container.chosen-with-drop .chosen-drop {
    display: block;
}
.chosen-container-single .chosen-single input[type="text"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}
