/*
--------------------------------------------------------------------------- ADMIN SPECIFIC STUFF

------------------------------------------------------------------------------------------------
*/

.admin-status-summary {
    @include clrfix;
    margin-bottom: 20px;
    @media screen and (min-width: $break-tablet) {//  720/16
        margin-bottom: 40px;
    }
}

.admin-status-summary p {
    margin-bottom:4px;
    @include fontSize(18px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(28px);
    }
}

.admin-status-summary p span {
    @include fontSize(14px);
    @media screen and (min-width: $break-tablet) {//  720/16
        @include fontSize(18px);
    }
}

.admin-status-summary a {
}


    
@media screen and (min-width: $break-desktop) {//  720/16
    
    .form-with-profile-pic {
        display:flex;
    }

    .form-with-profile-pic-form {
        flex: 1;
        order: 1;
        margin-right:60px;
    }

    .form-profile-pic-pic {
        order: 2;
        -ms-flex-preferred-size: 320px;
        flex-basis: 320px;
        max-width: 320px;
    }

}

