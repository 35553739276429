/*
------------------------------------------------------------------------------------ SITE HEADER

------------------------------------------------------------------------------------------------
*/

@import "site-menu-btn";

/* STRUCTURE */

.header {
    position: relative;
}

.header::before {
    content:'';
    //
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color: $black;
    opacity: 1;
    //
    z-index: 1;
    // 
    transition: opacity 0.2s linear;
}

.header-scrolled .header::before {
    opacity: 1;
}

.header .row {
    padding-left: 0; 
    padding-right: 0;
    width:100%;
    //
    position: relative;
    z-index: 1;
}

.header-elements {
    @include clrfix; 
    display:flex;
    align-items: center;
    min-height:52px;
    @media screen and (min-width: $break-tablet) {//  720/16
        border: 0;
        display:block;
    }
}

.header-inner {
    @media screen and (min-width: $break-tablet) {//  720/16
        display:flex;
        align-items: center;
    }
}

/* LOGO */

.header .logo {
    display:block;
    padding: 0;
    //
    max-width:160px;
    margin: 10px 90px 10px 15px;
    //
    @media screen and (min-width: $break-tablet) {//  720/16
        max-width:174px; 
        margin: 24px 20px;
    }
    @media screen and (min-width: $break-desktop) {//  720/16
        margin-left: 50px;
    }
    @media screen and (min-width: $break-desktop-wide) {
        margin-right: 40px;
    }
}

.header .logo img {
    margin:0;
    display:block;
    width:100%; /*Important for SVG logos */
}

/* LOGIN BUTTONS */

.header-additional {
    @media screen and (min-width: $break-tablet) {
        margin-left:auto;
        display: flex;
    }
    @media screen and (min-width: $break-desktop) {
        padding-right:30px;
    }
}

.header-additional .btn {
    margin-bottom: 0;
    @include fontSize(14px);
    font-family: $font-family-medium;
    @include font-smoothing(on);
    // make smaller on mobile only
    min-width:90px;
    @media screen and (max-width: $break-desktop) {
        @include fontSize(13px); 
        padding: 5px 16px 5px 16px;
        min-width: 100px;
    }
}

.header-additional .btn.btn-link {
    @media screen and (max-width: $break-desktop) {
        margin-right:0;
    }
}

.header-additional .nav-sign-in {
    position:absolute;
    top:11px;
    right:10px;
    margin-right:14px;
    padding-left:10px;
    padding-right:10px;
    @media screen and (min-width: $break-landscape) {//  720/16
        margin-right:20px;
        padding-left:16px;
        padding-right:16px;
    }
    @media screen and (min-width: $break-tablet) {//  720/16
        position:relative;
        top:auto;
        right:auto;
    }
}

.js .header-additional .nav-sign-in {
    right:50px;
    @media screen and (min-width: $break-tablet) {//  720/16
        right:auto;
    }
}

.header-additional .dropdown-toggle {
    flex-shrink: 0;
}

/* SHOW HIDE ITEMS */

.header .nav-tablet {
    display: none;
    @media screen and (min-width: $break-tablet) {//  720/16
        display: block;
    }
}

.header .nav-mob {
    @media screen and (min-width: $break-tablet) {//  720/16
        display: none;
    }
}

/* NAV OPEN / CLOSE BUTTON */

.header .menu-btn {
    display:none; 
    margin: 0 9px 0 auto;
}

.js .header .menu-btn {
    display:block;
    @media screen and (min-width: $break-tablet) {
        display:none;
    }
}

/* JS fixed header */

.js .header {
    position: fixed;
    top:0;
    width:100%;
    //height:100%;
    z-index: $z-navigation;
    //
    display: flex;
    flex-direction: column;
    // 
    @media screen and (min-width: $break-tablet) {
        height:auto;
        display:block;
    }

}

.header-spacer {
    height: 52px;
    @media screen and (min-width: $break-tablet) {
        height: 82px;
    }
}


/* HEADER DROPDOWNS */

.header-admin-nav-link {
    color:$primary;
    font-weight: 300;
}

.header-admin-nav-link:hover {
    color:$primary;
}

.header-admin-nav-link .icon-user-small {
    margin-top:2px;
}

.header-user-dropdown {
    @media screen and (min-width: $break-tablet) {//  720/16
        margin-right:20px;
    }
}


.header-user-dropdown-link {
    position: absolute;
    top: 4px;
    right: 62px;
    padding: 10px 12px;
    @media screen and (min-width: $break-tablet) {//  720/16
        position: relative;
        top: auto;
        right: auto;
        padding: 0;
    }
}

.header-user-dropdown-link .icon-ratio,
.header-user-dropdown-link > span,
.header-down-arrow {
    float:left;
}

.header-user-dropdown-link > span {
    display:none;
    @media print, screen and (min-width: $break-tablet) {//  720/16
        display:inline;
    }
    padding: 0 8px 0 10px;
}

.header-down-arrow {
    display:none;
    @media screen and (min-width: $break-tablet) {//  720/16
        display:inline;
    }
    padding-top:7px;
}


.header .dropdown-menu,
.header .dropdown-block {
    margin-top: 8px;
    left:auto;
    right:8px;
    max-width: 95%;
    @media screen and (max-width: $break-tablet) {//  720/16
        width:100%;
    }
    @media screen and (min-width: $break-tablet) {//  720/16
        margin-top: 6px;
        right:-3px;
        max-width: none;
    }
}

.header .header-user-dropdown .dropdown-block {
    @media screen and (min-width: $break-tablet) {//  720/16
        right:-7px;
    }
}

.header .dropdown-toggle {
    position: static;
    @media screen and (min-width: $break-tablet) {//  720/16
        position: relative;
    }
}

.header .header-user-dropdown .dropdown-block:before {
    right:70px;
    @media screen and (min-width: $break-tablet) {//  720/16
        right:8px;
    }
}

.header .header-help-dropdown .dropdown-block:before {
    right:12px;
    @media screen and (min-width: $break-tablet) {//  720/16
        right:8px;
    }
}

.header .dropdown-menu li {
    padding:0;
}

.header .dropdown-menu a,
.header .dropdown-block a {
    text-decoration: none;
    display: block;
    color:$text-main;
    font-weight: 300;
    padding: 4px 10px;
    transition: background-color 0.05s linear;
}

.header .dropdown-block li > span {
    display: block;
    padding: 4px 10px;
}

.header .dropdown-menu a:hover,
.header .dropdown-menu a:focus,
.header .dropdown-block a:hover,
.header .dropdown-block a:focus {
    //text-decoration: underline;
    background-color: $bg-grey-paler;
}

.header-user-nav {
    list-style: none;
    margin:0;
    padding:0;
}